import React, { useEffect, useRef } from 'react';
import styles from './dialog.module.scss';
import HeaderTab from './HeaderTab';
import Button, { BUTTON_VARIATIONS } from '@yoti/react-components/button';

interface Props {
    title: string;
    action: string;
    onConfirm: () => void;
    onClose: () => void;
    message: string;
}

const Dialog: React.FC<Props> = (props) => {
    const { title, message, action, onConfirm, onClose } = props;
    const ref = useRef<HTMLDivElement>(null);

    const closeAndExecute = () => {
        onConfirm();
        onClose();
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                onClose();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClose]);

    return (
        <div>
            <div className={styles['background']}></div>
            <div ref={ref} className={styles['dialog']}>
                <HeaderTab tabs={[title]}></HeaderTab>
                <div className={styles['dialog__box']}>
                    {`Are you sure you want to ${message}?`}
                    <div className={styles['dialog__box__buttons-group']}>
                        <div className={styles['dialog__box__buttons-group__button']}>
                            <Button
                                data-qa={'dialog-confirm-button'}
                                variation={BUTTON_VARIATIONS.PRIMARY}
                                onClick={closeAndExecute}
                            >
                                {action}
                            </Button>
                        </div>
                        <div className={styles['dialog__box__buttons-group__button']}>
                            <Button
                                data-qa={'dialog-cancel-button'}
                                variation={BUTTON_VARIATIONS.SECONDARY}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dialog;
