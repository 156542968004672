import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AccountService from '../../api/account/account';
import { providers } from '../../api/account/factory';
import { useQuery } from '../../hooks';
import { Login, LoginType } from '../../models/account';
import Config from '../../utils/config';
import CircularLoading from './../loading';
import styles from './callback.module.scss';
import { useGlobalContext } from '../../globalContext';
import { LOGIN_PATH, ROOT_PATH, VERIFY_ACCOUNT_PATH } from '../../utils/constants/Routes';

const accountService = new AccountService(Config.apiUrl);

interface Props {
    onLogin?: () => void;
}

interface Params {
    provider: LoginType;
}

const Callback: React.FC<Props> = ({ onLogin }) => {
    const query = useQuery();
    const params = useParams<Params>();
    const history = useHistory();

    const provider = params.provider;
    const callback = provider ? providers[provider].callback : undefined;
    const { account } = useGlobalContext();

    if (account) {
        history.replace(ROOT_PATH);
    }

    /**
     * Get the login token to request the external service in order to get the
     * user data. It returns undefined if the callback is not able to return the
     * information.
     */
    const getData = () => {
        try {
            return callback ? callback(query) : undefined;
        } catch {
            return undefined;
        }
    };
    const data = getData();

    const dataRef = JSON.stringify(data);
    useEffect(() => {
        // If user is already logged skip the rest of the code.
        if (account) return;
        // Take callback and check if it exists.
        if (!dataRef) return;
        const data = JSON.parse(dataRef) as Login;

        // Run callback request to get account info.
        accountService
            .callback(data)
            .then((session) => {
                if (session.verified && !session.banned) {
                    if (onLogin) onLogin();
                    const redirect = data.redirect || ROOT_PATH;
                    history.replace(redirect);
                } else {
                    history.replace({ pathname: VERIFY_ACCOUNT_PATH, state: { ...session } });
                }
            })
            .catch(() => {
                history.replace(LOGIN_PATH);
            });
    }, [dataRef, onLogin, history, account]);

    return (
        <div className={styles['callback__loading-container']}>
            <CircularLoading />
        </div>
    );
};

export default Callback;
