import { User } from './../models/account';

export type NodeEnv = typeof process.env.NODE_ENV;

type FilteredKeys<T, U> = {
    [P in keyof T]: T[P] extends U ? P : never;
}[keyof T];
/**
 * Returns a type with the properties in T that are F data type.
 *
 * FilteredType<{
 *   a: number
 *   b: string
 * }, number> => { a: number }
 *
 * This can be used in case we have a method that expects a property name in T
 * and the value for a specific data type (i.e. func method(value: number,
 * property: keyof T) {}). In this case we cannot loop all properties in
 * original type to call that method because `b` property is a string. So we can
 * create a type that filters all properties by a data type (number in this
 * case) and them loop the properties and call the method.
 */

export type FilteredType<T, F> = {
    [Q in FilteredKeys<T, F>]: T[Q];
};

// User
export type UserBooleanField = keyof FilteredType<User, boolean>;

export function getBooleanProperty(user: User, propertyName: UserBooleanField) {
    return user[propertyName];
}

export function getArrayFromString(s: string) {
    return s.replace(/\s/g, '').split(',');
}
