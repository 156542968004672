import React from 'react';
import {
    FaceCaptureError,
    Props as FaceCaptureErrorProps,
} from './face-capture-error/FaceCaptureError';

interface Props extends FaceCaptureErrorProps {
    children: JSX.Element;
}

export const FCMErrorHandler: React.FC<Props> = ({ errorMessage, onReset, children }) =>
    errorMessage ? <FaceCaptureError errorMessage={errorMessage} onReset={onReset} /> : children;
