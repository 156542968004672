import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { ConfigProps } from './components/config/ConfigPanel';
import {
    FaceMatchingPanelTabs,
    FACE_MATCHING_CONFIG,
} from './components/face-matching-panel/models';
import { DEFAULT_CONFIG as SCAN_DEFAULT_CONFIG } from './components/scan-panel/models';
import { ScanTabs } from './components/scan/models';
import { FirstLanding, FIRST_LANDING_DEFAULT_CONFIG } from './hooks/firstLanding';
import { Session } from './models/account';
import { RoleCode } from './models/account';

export interface AuthConfigProps {
    exp: Date;
    token: string;
    role: RoleCode;
    verified: boolean;
    banned?: boolean;
}

export function IsAuthConfig(config: any): config is AuthConfigProps {
    const typedConfig = config as AuthConfigProps;

    if (
        !typedConfig.exp ||
        !typedConfig.token ||
        !typedConfig.role ||
        !typedConfig.verified ||
        (typedConfig.banned && typedConfig.banned === undefined)
    ) {
        return false;
    }

    return true;
}

export interface Props {
    account?: Session;
    accountChecked: boolean;
    set: Dispatch<SetStateAction<Props>>;
    isTinyFaceReady: boolean;
    setIsTinyFaceReady: Dispatch<SetStateAction<boolean>>;
    /** The scan tab the user visited the last. */
    scanSelectedTab?: ScanTabs;
    /** The face matching tab the user visited the last. */
    faceMatchingSelectedTab?: FaceMatchingPanelTabs;
    /** Scan configuration. */
    scanConfiguration: ConfigProps;
    /** Scan configuration setter. */
    setScanConfiguration: (config: ConfigProps) => void;
    /** Face matching configuration. */
    faceMatchingConfiguration: ConfigProps;
    /** Face matching configuration setter. */
    setFaceMatchingConfiguration: (config: ConfigProps) => void;
    /** First landing configuration section. */
    firstLandingConfig: FirstLanding;
}

export const baseDefaultValue: Props = {
    accountChecked: false,
    set: () => {}, // Mock method to implement 'Props'.
    isTinyFaceReady: false,
    setIsTinyFaceReady: () => {},
    scanSelectedTab: undefined,
    faceMatchingSelectedTab: undefined,
    scanConfiguration: SCAN_DEFAULT_CONFIG,
    setScanConfiguration: () => {},
    faceMatchingConfiguration: FACE_MATCHING_CONFIG,
    setFaceMatchingConfiguration: () => {},
    firstLandingConfig: FIRST_LANDING_DEFAULT_CONFIG,
};

export const getDefaultValue = (firstLandingConfig: FirstLanding): Props => {
    return {
        ...baseDefaultValue,
        firstLandingConfig,
        ...ConfigProps,
    };
};

const GlobalContext = createContext(baseDefaultValue);
export default GlobalContext;

export const useGlobalContext = () => useContext(GlobalContext);

export const isLoggedIn = (account?: { token: string }) => {
    return !!account && account.token;
};

export const isAppReady = (context: Props) => {
    return context.accountChecked;
};
