import React, { useEffect } from 'react';
import { FirstLanding } from '../../hooks/firstLanding';
import {
    AGE_ANTISPOOFING_CONFIG_KEY,
    FACE_MATCHING_CONFIG_KEY,
} from '../../utils/constants/storage';
import { TwoSidesPanel } from '../commons';
import Guidance from './Guidance';
import UserGuide from './UserGuide';

interface Props {
    section: typeof AGE_ANTISPOOFING_CONFIG_KEY | typeof FACE_MATCHING_CONFIG_KEY;
    firstLandingConfig: FirstLanding;
    set: (previous: any) => void;
    children: JSX.Element[];
}

/**
 * Component to print the Guide tab. It will print a enumerated list of steps
 * and helpfull guidance for the user.
 *
 * @param config The current configuration of the app. It will be used to check if it
 * is user first landing.
 * @param setConfig Function to change the config indicating that the user has
 * landed once in the guide page.
 * @param children A different step number will be assigned to each child of the component.
 */
const GuidePanel: React.FC<Props> = (props) => {
    const { section, firstLandingConfig, children, set } = props;
    useEffect(() => {
        switch (section) {
            case AGE_ANTISPOOFING_CONFIG_KEY:
                if (firstLandingConfig.scanFirstLanding) {
                    firstLandingConfig.scanFirstLanding = false;
                    firstLandingConfig.setScanFirstLanding(false);
                }
                break;
            case FACE_MATCHING_CONFIG_KEY:
                if (firstLandingConfig.faceMatchingFirstLanding) {
                    firstLandingConfig.faceMatchingFirstLanding = false;
                    firstLandingConfig.setFaceMatchingFirstLanding(false);
                }
        }
        // It is required update firstLandingConfig by general set instead by its set
        // because its set only update the value once the page has been refresh.
        set((previous: any) => ({
            ...previous,
            firstLandingConfig: firstLandingConfig,
        }));
    }, [firstLandingConfig, section, set]);

    return (
        <>
            <TwoSidesPanel>
                {{
                    Left: <UserGuide>{children}</UserGuide>,
                    Right: <Guidance />,
                }}
            </TwoSidesPanel>
        </>
    );
};

export default GuidePanel;
