import React from 'react';
import { RawAgeDisplayerProps } from '../raw-age-displayer/RawAgeDisplayer';
import styles from '../../ageAntispoofingResponse.module.scss';

export type Interval = 1 | 2 | 3 | 4;

export interface RangeAgeDisplayerProps extends RawAgeDisplayerProps {
    interval: Interval;
}

export const RangeAgeDisplayer: React.FC<RangeAgeDisplayerProps> = ({ age, interval }) => {
    const roundedAge = Math.round(age);
    const formattedAge = `(${roundedAge - interval} - ${roundedAge + interval})`;
    return (
        <span data-qa="age-result" className={styles['response__text']}>
            {formattedAge}
        </span>
    );
};
