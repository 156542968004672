import React from 'react';

export enum MediaQuery {
    sm = 576,
    md = 768,
    lg = 992,
    xl = 1200,
}

export function useMediaQuery(query: MediaQuery): boolean {
    const [width, setWidth] = React.useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    // We use `>` because we are using a mobile first pattern for the css.
    // This way both media queries will be following the same pattern.
    return width >= query;
}

export default useMediaQuery;
