import React from 'react';
import styles from './configPanel.module.scss';
import classNames from 'classnames';

interface Props {
    title: string;
    className?: string;
}

export const ConfigSection: React.FC<Props> = ({ title, children, className }) => {
    return (
        <div className={classNames(styles.panel__section, className)}>
            <h1 className={styles['panel__section-title']}>{title}</h1>
            <div className={styles['panel__section-body']}>{children}</div>
        </div>
    );
};
