import React from 'react';
import ImageConfigPanel from './ImageConfigPanel';
import Config from '../../utils/config';
import { UserBooleanField } from './../../utils/types';

interface Props {
    property: UserBooleanField;
    onError: (error: Error) => void;
}

export const ConfigWrapper: React.FC<Props> = ({ property, onError }) => {
    const showStoreImagesOption = Config.showStoreImagesOption;

    return (
        <>{showStoreImagesOption && <ImageConfigPanel onError={onError} property={property} />}</>
    );
};
