import { RoleCode } from '../models/account';
import Config from '../utils/config';
import { FCMPayload } from './faceCaptureModule';

// Endpoints information can be found here: https://lampkicking.atlassian.net/wiki/spaces/AS/pages/2036662599/AI+Services+Production+Internal+API+v1.4.0
export enum Endpoint {
    Antispoofing = 'v1/antispoofing',
    Age = 'v1/age',
    AgeAntispoofing = 'v1/age-antispoofing',
    FaceMatching = 'face-matching',
    AgeAntispoofingVerify = 'v1/age-antispoofing-verify',
    AgeVerify = 'v1/age-verify',
    SelfCheckoutAntiSpoofing = 'v1/self-checkout/age-antispoofing',
}

export enum Sections {
    Endpoint = 'endpoint',
    Metadata = 'metadata',
    AgeEstimationModel = 'ageEstimationModel',
    LevelOfAssurance = 'levelOfAssurance',
    Operator = 'operator',
    Threshold = 'threshold',
    SecureMode = 'secureMode',
    AgeDisplayMode = 'ageDisplayMode',
    Language = 'language',
    ConfigWrapper = 'configWrapper',
}

const AnyRole = 'any';

export const getEndpointName = (endpoint: Endpoint) => {
    switch (endpoint) {
        case Endpoint.Antispoofing:
            return 'Antispoofing';
        case Endpoint.AgeAntispoofing:
            return 'Age Antispoofing';
        case Endpoint.Age:
            return 'Age';
        case Endpoint.FaceMatching:
            return 'Face Matching';
        case Endpoint.AgeAntispoofingVerify:
            return 'Age Antispoofing Verify';
        case Endpoint.AgeVerify:
            return 'Age Verify';
        case Endpoint.SelfCheckoutAntiSpoofing:
            return 'Self-Checkout Age Antispoofing';
        default:
            return 'Unknown';
    }
};

export enum AgeEstimationModelField {
    Mar23 = 'mar23',
    Aug24 = 'aug24',
}

export enum MetadataField {
    Mobile = 'mobile',
    Laptop = 'laptop',
    Unknown = 'unknown',
    Any = 'any',
}

export enum LevelOfAssuranceField {
    Low = 'low',
    Medium = 'medium',
    High = 'high',
    VeryHigh = 'very_high',
}

export enum OperatorField {
    Under = 'UNDER',
    Over = 'OVER',
}

export enum ExpectedResult {
    Real = 'real',
    DisplayAttack = 'display_attack',
    PrintAttack = 'print_attack',
    MaskAttack = 'mask_attack',
}

export enum ExpectedLocation {
    Indoor = 'indoor',
    Outdoor = 'outdoor',
}

export enum ExpectedBrightness {
    Dark = 'dark',
    Normal = 'normal',
    Bright = 'bright',
}

export const getMetadataFieldName = (metadata: MetadataField) => {
    switch (metadata) {
        case MetadataField.Mobile:
            return 'Mobile';
        case MetadataField.Laptop:
            return 'Laptop';
        case MetadataField.Any:
            return 'Any';
        default:
            return 'Unknown';
    }
};

export const getOperatorFieldName = (operator: OperatorField): string => {
    return operator;
};

function includesRoleOrAny(base: string[], searchRole: string | undefined): boolean {
    return (searchRole !== undefined && base.includes(searchRole)) || base.includes(AnyRole);
}

export const validRoleConfig = (setting?: Sections, role?: RoleCode) => {
    switch (setting) {
        case Sections.Endpoint:
            return includesRoleOrAny(Config.allowedRolesForEndpointsConfig, role);
        case Sections.Metadata:
            return includesRoleOrAny(Config.allowedRolesForMetadataConfig, role);
        case Sections.LevelOfAssurance:
            return includesRoleOrAny(Config.allowedRolesForLevelOfAssuranceConfig, role);
        case Sections.AgeEstimationModel:
            return includesRoleOrAny(Config.allowedRolesForAgeEstimationModelConfig, role);
        case Sections.Operator:
            return includesRoleOrAny(Config.allowedRolesForOperatorConfig, role);
        case Sections.Threshold:
            return includesRoleOrAny(Config.allowedRolesForThresholdConfig, role);
        case Sections.SecureMode:
            return includesRoleOrAny(Config.allowedRolesForSecureModeConfig, role);
        case Sections.ConfigWrapper:
            return includesRoleOrAny(Config.allowedRolesForConfigWrapperConfig, role);
        case Sections.AgeDisplayMode:
            return includesRoleOrAny(Config.allowedRolesForAgeDisplayModeConfig, role);
        case Sections.Language:
            return includesRoleOrAny(Config.allowedRolesForLanguageConfig, role);
    }
};

export interface RequestMetadataField {
    device: string;
}

export const getMetadataStruct = (
    metadataDeviceField?: MetadataField,
): RequestMetadataField | undefined => {
    if (!metadataDeviceField) {
        return undefined;
    }
    return { device: metadataDeviceField };
};

export enum Liveness {
    Real = 'real',
    Fake = 'fake',
    Undetermined = 'undertermined',
    Fail = 'fail',
    Pass = 'pass',
}

export function LivenessOutput(result: Liveness): string {
    switch (result) {
        case Liveness.Real:
            return 'Passed';
        case Liveness.Fake:
            return 'Failed';
        case Liveness.Undetermined:
            return 'Undetermined';
        case Liveness.Fail:
            return 'Failed';
        case Liveness.Pass:
            return 'Passed';
    }
}

export interface PredictionResponse {
    prediction?: Liveness;
    age?: number;
    stDev?: number;
    errorMessage?: string;
    errorCode?: string;
    ageCheck?: Liveness;
}

export interface FaceMatchingResponseData {
    score: number;
    errorMessage?: string;
    errorCode?: string;
}

export interface RequestBody extends FCMPayload {
    metadata?: RequestMetadataField;
    level_of_assurance?: LevelOfAssuranceField;
    age_estimation_model?: AgeEstimationModelField;
    operator?: OperatorField;
    threshold?: number;
}

export enum SecureMode {
    NonSecure = 'nonSecure',
    Secure = 'secure',
}

export const getSecureModeName = (mode: SecureMode) => {
    switch (mode) {
        case SecureMode.NonSecure:
            return 'Non secure';
        case SecureMode.Secure:
            return 'Secure';
        default:
            return 'Unknown';
    }
};

export enum AgeDisplayMode {
    Range = 'range',
    Raw = 'raw',
}
