import React from 'react';
import { OperatorField, getOperatorFieldName } from '../../../models/prediction';
import { ConfigSection } from '../ConfigSection';
import RadioButtonList, { RadioButtonEntry } from '../radioButtonsList';

export interface ConfigOperatorProps {
    operatorFieldList: OperatorField[];
    value?: OperatorField;
    onChange: (value?: OperatorField) => void;
    disabled: boolean;
}

const OperatorFieldSection: React.FC<ConfigOperatorProps> = ({
    operatorFieldList,
    value: selectedOperatorField,
    onChange,
    disabled,
}) => {
    const operatorRadioButtonList: RadioButtonEntry<OperatorField>[] = operatorFieldList.map(
        (field) => {
            return { value: field, label: getOperatorFieldName(field) };
        },
    );
    const onOperatorChange = (element: OperatorField) =>
        onChange(element === selectedOperatorField ? undefined : element);

    return (
        <ConfigSection title="Operator">
            <RadioButtonList
                list={operatorRadioButtonList}
                selectedValue={selectedOperatorField}
                onChange={onOperatorChange}
                disabled={disabled}
            />
        </ConfigSection>
    );
};

export default OperatorFieldSection;
