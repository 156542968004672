import React from 'react';
import styles from './guidance.module.scss';
import { useMediaQuery, MediaQuery } from '../../hooks';

import YotiLightIcon from '../../assets/icons/Yoti_icon_light.svg';
import YotiEyewearIcon from '../../assets/icons/Yoti_icon_eyewear.svg';
import YotiHeadwearIcon from '../../assets/icons/Yoti_icon_headwear.svg';
import YotiLightIconLight from '../../assets/icons/Yoti_icon_light_light.svg';
import YotiEyewearIconLight from '../../assets/icons/Yoti_icon_eyewear_light.svg';
import YotiHeadwearIconLight from '../../assets/icons/Yoti_icon_headwear_light.svg';
import { ConfigSection } from '../config/ConfigSection';

const Guidance: React.FC = () => {
    const isLaptop = useMediaQuery(MediaQuery.lg);

    const lightIcon = isLaptop ? YotiLightIconLight : YotiLightIcon;
    const eyewearIcon = isLaptop ? YotiEyewearIconLight : YotiEyewearIcon;
    const headwearIcon = isLaptop ? YotiHeadwearIconLight : YotiHeadwearIcon;
    return (
        <ConfigSection className={styles['guidance__title']} title="Remember to:">
            <div className={styles['guidance']}>
                <div className={styles['guidance__element']}>
                    <div className={styles['guidance__element__icon-wrapper']}>
                        <img src={lightIcon} alt={'Find a light area'} />
                    </div>
                    <div>Find a light area</div>
                </div>
                <div className={styles['guidance__element']}>
                    <div className={styles['guidance__element__icon-wrapper']}>
                        <img src={headwearIcon} alt={'Remove any headwear'} />
                    </div>
                    <span>Remove any headwear</span>
                </div>
                <div className={styles['guidance__element']}>
                    <div className={styles['guidance__element__icon-wrapper']}>
                        <img src={eyewearIcon} alt={'Keep your glasses on'} />
                    </div>
                    <span>Keep your glasses on</span>
                </div>
            </div>
        </ConfigSection>
    );
};

export default Guidance;
