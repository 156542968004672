/**
 * Returns an array of base64 images entities decoded from given file list.
 * @param files Files to be decoded.
 */
export const readFiles = async (files: FileList | File[] | null): Promise<string[]> => {
    const result: string[] = [];
    if (!files) {
        return result;
    }

    await Promise.all(
        Array.from(files).map(async (file): Promise<number> => result.push(await readImg(file))),
    );

    return result;
};

/**
 * Get the base64 string representation of the image from an image file.
 * @param img An image file.
 */
const readImg = (img: File): Promise<string> => {
    return new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            if (typeof reader.result == 'string') {
                resolve(reader.result);
            }
        };
        reader.readAsDataURL(img);
    });
};
