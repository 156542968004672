import Button, { BUTTON_SIZES, BUTTON_VARIATIONS } from '@yoti/react-components/button';
import { CheckboxBase } from '@yoti/react-components/checkbox';
import React, { useState } from 'react';
import { useGlobalContext } from '../../globalContext';
import Config from '../../utils/config';
import { HeaderTab } from '../commons';
import styles from './screen.module.scss';

const Screen: React.FC = ({ children }) => {
    const { scanConfiguration, setScanConfiguration } = useGlobalContext();
    const consent = scanConfiguration?.consent;
    const [consentValue, setConsentValue] = useState(false);

    const allowConsent = () => {
        setConsentValue(false); // Reset the checkbox value in case the user come back to this screen.
        setScanConfiguration({ ...scanConfiguration, consent: true });
    };

    if (Config.showConsent && !consent) {
        return (
            <>
                <HeaderTab tabs={['Consent']} />
                <div className={styles['consent']}>
                    <p className={styles['consent__text']}>
                        By proceeding I understand that R&D will use the data collected (image and
                        associated device information metadata) for the purpose of improving our
                        models. R&D will test models on the data to assess model performance.
                    </p>
                    <p className={styles['consent__text']}>
                        If you would like to have your images removed from this process at any point
                        in the future you can email rand@yoti.com
                    </p>
                    <br />
                    <div className={styles['consent__form']}>
                        <CheckboxBase
                            label="I accept to share my data with Yoti"
                            onChange={() => {
                                setConsentValue(!consentValue);
                            }}
                            checked={consentValue}
                        />

                        <br />
                        <br />

                        <Button
                            variation={BUTTON_VARIATIONS.PRIMARY}
                            size={BUTTON_SIZES.SMALL}
                            onClick={allowConsent}
                            disabled={!consentValue}
                        >
                            Allow
                        </Button>
                    </div>
                </div>
            </>
        );
    }

    return <>{children}</>;
};

export default Screen;
