import { Login, LoginType } from '../../models/account';
import { NodeEnv } from '../../utils/types';
import { begin as googleBegin, callback as googleCallback } from './google';
import { begin as yotiBegin, callback as yotiCallback } from './yoti';

/**
 * OAuthProvider is the entity that will handle the OAuth authentication for a
 * specific provider.
 */
interface OAuthProvider {
    /**
     * Handle the first response from back-end server that generates the token
     * and redirects to the OAuth endpoint in order to get the credentials.
     */
    begin: (params: URLSearchParams, env: NodeEnv) => Promise<void>;
    /**
     * Handles the callback once the OAuth entity provides the credential token
     * to the application.
     */
    callback: (params: URLSearchParams) => Login;
}

/**
 * This entity contains all the OAuth providers implementations that the
 * application will use for the user authentication.
 */
export const providers: Record<LoginType, OAuthProvider> = {
    google: { begin: googleBegin, callback: googleCallback },
    yoti: { begin: yotiBegin, callback: yotiCallback },
};
