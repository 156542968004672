import clone from 'clone';
import { LocationDescriptor } from 'history';

/**
 * Creates the base64 string with the information about the location provided.
 * @param location The location to generate the token.
 * @returns The base64 string with the location info.
 */
export const generateInfoToken = (location: LocationDescriptor) => {
    const result = clone(location);
    return btoa(JSON.stringify(result));
};

/**
 * Transforms the token provided into a location descriptor entity.
 * @param token The token to parse.
 * @returns The location info.
 */
export const getLocation = (token: string): LocationDescriptor => {
    const result = atob(token);
    return JSON.parse(result);
};
