import React, { useCallback, useEffect, useState } from 'react';
import { useNotify } from '../../hooks';
import { ALERT_TYPES } from '@yoti/react-components/alert';
import styles from './faceMatchingPanel.module.scss';
import { HeaderTab } from '../commons';
import { useGlobalContext } from '../../globalContext';
import { FaceMatchingPanelTabs } from './models';
import Loading from '../loading';
import { Guide, Settings } from './tabs';
import Capture, { FaceMatchingCaptureResponse } from './tabs/Capture';
import ResultFaceMatching from '../result/face-matching';

const homeTabs = Object.values(FaceMatchingPanelTabs);

const FaceMatchingPanel: React.FC = () => {
    const globalContext = useGlobalContext();
    const {
        faceMatchingSelectedTab: selectedTab,
        faceMatchingConfiguration: configuration,
        setFaceMatchingConfiguration: setConfiguration,
        set,
        firstLandingConfig,
    } = globalContext;

    const [result, setResult] = useState<FaceMatchingCaptureResponse>();

    const notify = useNotify();

    const notifyError = useCallback(
        (error: Error) => {
            notify({
                message: error.message,
                type: ALERT_TYPES.DANGER,
                icon: true,
            });
        },
        [notify],
    );

    const onError = useCallback((error: Error) => notifyError(error), [notifyError]);
    const onSuccess = useCallback(
        (res: FaceMatchingCaptureResponse) => {
            setResult(res);
        },
        [setResult],
    );

    const setTab = (tab: string) => {
        switch (tab) {
            case FaceMatchingPanelTabs.Settings:
            case FaceMatchingPanelTabs.Guide:
            case FaceMatchingPanelTabs.Capture:
                set((previous) => ({ ...previous, faceMatchingSelectedTab: tab }));
                break;
            default:
                set((previous) => ({
                    ...previous,
                    faceMatchingSelectedTab: FaceMatchingPanelTabs.Capture,
                }));
                console.log(new Error('Selected tab not available, redirected to Capture'));
                break;
        }
    };

    useEffect(() => {
        if (!selectedTab) {
            set((previous) => ({
                ...previous,
                faceMatchingSelectedTab: previous.firstLandingConfig.faceMatchingFirstLanding
                    ? FaceMatchingPanelTabs.Guide
                    : FaceMatchingPanelTabs.Capture,
            }));
        }
    }, [set, selectedTab]);

    const backCallback = useCallback(() => {
        setResult(undefined);
    }, []);

    return (
        <>
            {result ? (
                // TODO: AITOOL-1995: Also, we need to make a prediction for each one of these images.
                <ResultFaceMatching
                    firstImage={{ image: result.firstImage }}
                    secondImage={{ image: result.secondImage }}
                    result={result.responseData}
                    onBackButtonClick={backCallback}
                    configuration={configuration}
                ></ResultFaceMatching>
            ) : selectedTab === undefined ? (
                <Loading />
            ) : (
                <div className={styles['face-matching-panel']}>
                    <HeaderTab tabs={homeTabs} selected={selectedTab} onClick={setTab} />
                    <div className={styles['face-matching-panel__wrapper']}>
                        <div className={styles['face-matching-panel__wrapper__panel']}>
                            {selectedTab === FaceMatchingPanelTabs.Settings ? (
                                <Settings
                                    className={
                                        styles['face-matching-panel__fixed-size-wrapper__panel']
                                    }
                                    configuration={configuration}
                                    onConfigChange={setConfiguration}
                                    onError={onError}
                                />
                            ) : selectedTab === FaceMatchingPanelTabs.Guide ? (
                                <Guide
                                    className={
                                        styles['face-matching-panel__fixed-size-wrapper__panel']
                                    }
                                    firstLandingConfig={firstLandingConfig}
                                    set={set}
                                />
                            ) : (
                                <Capture
                                    onError={onError}
                                    onSuccess={onSuccess}
                                    language={configuration.selectedLanguage}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FaceMatchingPanel;
