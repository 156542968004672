import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../icon';
import { loadingSpinner as LoadingSpinner } from '../icons-list';

import './loading.scss';

function Loading({ size, color, className: extraClassName, alignFallbackLegacy }) {
  const getIconProps = (iconClass) => ({
    className: classNames(iconClass, extraClassName),
    size,
    color,
  });

  return (
    <div
      className={classNames('yoti-loading', { 'yoti-loading--central': !alignFallbackLegacy })}
      data-qa="loading"
    >
      <Icon component={LoadingSpinner} {...getIconProps('yoti-loading__icon')} />
    </div>
  );
}

Loading.defaultProps = {
  size: null,
  color: null,
  className: '',
  alignFallbackLegacy: false,
};

Loading.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  alignFallbackLegacy: PropTypes.bool,
};

export default Loading;
