import React from 'react';
import styles from './footer.module.scss';
import YotiPoweredIcon from '../../assets/Powered_by_Yoti_dark_horizontal.svg';

const Footer: React.FC = () => {
    return (
        <div className={styles.footer}>
            <img src={YotiPoweredIcon} alt={'Powered by Yoti'} />
        </div>
    );
};

export default Footer;
