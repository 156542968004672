import React from 'react';
import { LevelOfAssuranceField } from '../../../models/prediction';
import { ConfigSection } from '../ConfigSection';
import RadioButtonList, { RadioButtonEntry } from '../radioButtonsList';

export interface ConfigLevelOfAssuranceProps {
    levelOfAssuranceFieldList: LevelOfAssuranceField[];
    value?: LevelOfAssuranceField;
    onChange: (value?: LevelOfAssuranceField) => void;
    disabled: boolean;
}

const LevelOfAssuranceFieldSection: React.FC<ConfigLevelOfAssuranceProps> = ({
    levelOfAssuranceFieldList,
    value: selectedLevelOfAssuranceField,
    onChange,
    disabled,
}) => {
    const levelOfAssuranceRadioButtonList: RadioButtonEntry<LevelOfAssuranceField>[] = levelOfAssuranceFieldList.map(
        (field) => {
            return { value: field, label: field.charAt(0).toUpperCase() + field.slice(1) };
        },
    );
    const onLevelOfAssuranceChange = (element: LevelOfAssuranceField) =>
        onChange(element === selectedLevelOfAssuranceField ? undefined : element);

    return (
        <ConfigSection title="Level of Assurance">
            <RadioButtonList
                list={levelOfAssuranceRadioButtonList}
                selectedValue={selectedLevelOfAssuranceField}
                onChange={onLevelOfAssuranceChange}
                disabled={disabled}
            />
        </ConfigSection>
    );
};

export default LevelOfAssuranceFieldSection;
