import React from 'react';
import { SecureMode, getSecureModeName } from '../../../models/prediction';
import { ConfigSection } from '../ConfigSection';
import RadioButtonList, { RadioButtonEntry } from '../radioButtonsList';

export interface ConfigSecureModeProps {
    secureModeList: SecureMode[];
    value: SecureMode;
    onChange: (value: SecureMode) => void;
}

const SecureModeSection: React.FC<ConfigSecureModeProps> = ({
    secureModeList,
    value: selectedSecureMode,
    onChange,
}) => {
    const secureModeRadioButtonList: RadioButtonEntry<SecureMode>[] = secureModeList.map(
        (field) => {
            return { value: field, label: getSecureModeName(field) };
        },
    );

    return (
        <ConfigSection title="Secure mode">
            <RadioButtonList
                list={secureModeRadioButtonList}
                selectedValue={selectedSecureMode}
                onChange={onChange}
            />
        </ConfigSection>
    );
};

export default SecureModeSection;
