import Axios from 'axios';
import { Login, LoginResponse, LoginSettings, LoginType, Session } from '../../models/account';
import { SESSION_KEY } from '../../utils/constants/storage';
import { ApiBase, REDIRECT_QUERY_STRING_PARAM, removeLocalSessionData } from '../base';

function getStoredSession() {
    const sessionData = localStorage.getItem(SESSION_KEY);
    if (sessionData) {
        return JSON.parse(sessionData) as Session;
    }
}

// Set interceptor to send the session token for all requests.
Axios.interceptors.request.use((config) => {
    const session = getStoredSession();
    if (session) {
        config.headers.Authorization = `Bearer ${session.token}`;
    }
    return config;
});

class AccountService extends ApiBase {
    async login<T extends LoginType>(type: T, redirectToken?: string): Promise<LoginResponse<T>> {
        let url = `auth/begin/${type}`;
        if (redirectToken) {
            url += `?${REDIRECT_QUERY_STRING_PARAM}=${redirectToken}`;
        }

        const res = await this.fetch<LoginResponse<T>>(url);
        res.type = type;
        return res;
    }

    async callback(info: Login): Promise<Session> {
        const session = await this.fetch<Session>(`auth/callback/${info.type}`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: JSON.stringify(info.data),
        });

        localStorage.setItem(SESSION_KEY, JSON.stringify(session));
        return session;
    }

    logout(): Promise<void> {
        removeLocalSessionData();
        return Promise.resolve();
    }

    checkSession(): Promise<Session> {
        return new Promise<Session>((resolve, reject) => {
            this.fetch('auth')
                .then(() => {
                    const session = getStoredSession();
                    if (session) {
                        resolve(session);
                    } else {
                        reject(new Error('no local session'));
                    }
                })
                .catch((e) => reject(e));
        });
    }

    loginCfg(): Promise<LoginSettings> {
        return this.fetch<LoginSettings>('login-cfg');
    }
}

export default AccountService;
