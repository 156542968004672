import React from 'react';
import { AgeDisplayMode } from '../../../models/prediction';
import { ConfigSection } from '../ConfigSection';
import RadioButtonList, { RadioButtonEntry } from '../radioButtonsList';

export interface AgeDisplayModeSectionProps {
    ageDisplayModeList: AgeDisplayMode[];
    value: AgeDisplayMode;
    disabled: boolean;
    onChange: (value: AgeDisplayMode) => void;
}

const AgeDisplayModeSection: React.FC<AgeDisplayModeSectionProps> = ({
    ageDisplayModeList,
    value,
    disabled,
    onChange,
}) => {
    const ageDisplayModeRadioButtonList: RadioButtonEntry<AgeDisplayMode>[] = ageDisplayModeList.map(
        (value) => ({
            label: formatAgeDisplayMode(value),
            value,
        }),
    );

    return (
        <ConfigSection title="Age display mode">
            <RadioButtonList
                list={ageDisplayModeRadioButtonList}
                selectedValue={value}
                disabled={disabled}
                onChange={onChange}
            />
        </ConfigSection>
    );
};

const formatAgeDisplayMode = (mode: AgeDisplayMode) =>
    `${mode.charAt(0).toUpperCase()}${mode.slice(1)}`;

export default AgeDisplayModeSection;
