import { useContext, useEffect } from 'react';
import GlobalContext from '../globalContext';
import AccountService from '../api/account/account';
import Config from '../utils/config';
import { Session } from '../models/account';
import { useNotify } from '.';
import { ALERT_TYPES } from '@yoti/react-components/alert';

const accountService = new AccountService(Config.apiUrl);

function useCheckSession(anonymous?: boolean): { account?: Session; accountChecked: boolean } {
    const globalContext = useContext(GlobalContext);
    const notify = useNotify();
    const { set, account, accountChecked } = globalContext;

    useEffect(() => {
        if (!anonymous && !accountChecked) {
            accountService
                .checkSession()
                .then((account) => {
                    set((previous) => ({ ...previous, account: account, accountChecked: true }));
                })
                .catch((e) => {
                    set((previous) => ({ ...previous, account: undefined, accountChecked: true }));
                    if (e && e.message) {
                        notify({
                            message: e.message,
                            type: ALERT_TYPES.DANGER,
                            icon: true,
                        });
                    }
                });
        }
    }, [accountChecked, anonymous, notify, set]);

    return { account, accountChecked };
}

export default useCheckSession;
