import Button, { BUTTON_SIZES, BUTTON_VARIATIONS } from '@yoti/react-components/button';
import { camera, upload } from '@yoti/react-components/icons-list';
import React, { useRef } from 'react';
import { getFaces } from '../../utils/faceDetection';
import { readFiles } from '../../utils/upload';
import { validateImageDimensions } from '../../validations/upload';
import DragAndDrop from '../drag-and-drop';
import TinyFace from '../tiny-face/TinyFace';
import styles from './selfieCapture.module.scss';

// Validation values for the uploaded image.
const VALID_FORMATS: string[] = ['png', 'jpeg'];
const mimeTypes = VALID_FORMATS.map((format) => `image/${format}`);
const FILES_LIMIT = 1;
const MIN_IMAGE_WIDTH = 300;
const MAX_IMAGE_WIDTH = 2000;
const MIN_IMAGE_HEIGHT = 300;
const MAX_IMAGE_HEIGHT = 2000;
const MIN_FILE_SIZE = 50;
const MAX_FILE_SIZE = 3010;
const THRESHOLD = 0.7;
const MAX_FACES = 1;
const UPLOAD_VALIDATION_REQUIREMENTS = [
    `Our valid image formats:${VALID_FORMATS.map((value) => ` ${value.toUpperCase()}`)}.`,
    `Pictures will not have resolutions lower than ${MIN_IMAGE_WIDTH}x${MIN_IMAGE_HEIGHT} 
    or greater than ${MAX_IMAGE_WIDTH}x${MAX_IMAGE_HEIGHT}.`,
    `Files size will not be lower than ${MIN_FILE_SIZE}KB 
    or greater than ${MAX_FILE_SIZE}KB.`,
    `Only ${MAX_FACES} face allowed.`,
    'Try to take the photo in a light area.',
];

interface Props {
    image: string;
    primaryPanel: boolean;

    onChange: (image: string) => void;
    onError: (error: Error) => void;
    onTakePhotoClick: () => void;
}

const SelfieCapture: React.FC<Props> = ({
    image,
    primaryPanel,
    onError,
    onTakePhotoClick,
    onChange,
}) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFiles = async (files?: FileList) => {
        if (!files) {
            onChange('');
            return;
        }

        const { size, type } = files[0];
        if (mimeTypes.indexOf(type) === -1) {
            onError(new Error('Format not allowed'));
            return;
        }

        if (files.length > FILES_LIMIT) {
            onError(new Error(`Too many files at the same time. Limit: ${FILES_LIMIT}`));
            return;
        }

        if (size > MAX_FILE_SIZE * 1000) {
            onError(new Error(`File size too large. Max size: ${MAX_FILE_SIZE} KB.`));
            return;
        }

        if (size < MIN_FILE_SIZE * 1000) {
            onError(new Error(`File size too small. Min size: ${MIN_FILE_SIZE} KB.`));
            return;
        }

        const images = await readFiles(files);
        if (!images.length) {
            return;
        }
        const image = images[0];
        const bboxes = await getFaces(image, THRESHOLD);
        const bBoxesLenght = bboxes.length;

        const errors = await validateImageDimensions(image, {
            maxWidth: MAX_IMAGE_WIDTH,
            minWidth: MIN_IMAGE_WIDTH,
            maxHeight: MAX_IMAGE_HEIGHT,
            minHeight: MIN_IMAGE_HEIGHT,
        });
        if (errors.length) {
            let err = '';

            errors.forEach((error) => {
                if (errors.indexOf(error) === errors.length - 1) {
                    err += error.message;
                } else {
                    err += `${error.message}, `;
                }
            });
            onError(new Error(err));
            return;
        }

        if (!bBoxesLenght) {
            onError(new Error(`No face detected`));
            return;
        }

        if (bBoxesLenght > MAX_FACES) {
            onError(
                new Error(`Too many faces, detected: ${bBoxesLenght}. Max allowed: ${MAX_FACES}.`),
            );
            return;
        }
        onChange(image);
    };

    const onFilesSelectedInput = () => {
        if (fileInputRef.current?.files?.length) {
            handleFiles(fileInputRef.current.files);
        }
    };

    const onClickFileInput = () => {
        if (!fileInputRef || !fileInputRef.current) return;
        fileInputRef.current.click();
    };

    return (
        <TinyFace onError={onError}>
            <div className={styles['selfie-capture']}>
                <div className={styles['selfie-capture__drag-drop']}>
                    <DragAndDrop
                        image={image}
                        onError={onError}
                        primaryPanel={primaryPanel}
                        infoMsgs={UPLOAD_VALIDATION_REQUIREMENTS}
                        onClick={onClickFileInput}
                        onFilesLoad={handleFiles}
                    />
                </div>
                <div className={styles['selfie-capture__button-wrap']}>
                    <Button
                        variation={BUTTON_VARIATIONS.SECONDARY}
                        size={BUTTON_SIZES.SMALL}
                        fullWidth
                        iconEnd={camera}
                        onClick={onTakePhotoClick}
                        data-qa="take-photo-button"
                    >
                        Capture
                    </Button>
                </div>
                <div className={styles['selfie-capture__button-wrap']}>
                    <Button
                        variation={BUTTON_VARIATIONS.SECONDARY}
                        size={BUTTON_SIZES.SMALL}
                        fullWidth
                        iconEnd={upload}
                        onClick={onClickFileInput}
                        data-qa="upload-photo-button"
                    >
                        Upload
                    </Button>
                </div>
                <input
                    ref={fileInputRef}
                    className={styles['selfie-capture__file-input']}
                    type="file"
                    onChange={onFilesSelectedInput}
                    data-qa="input"
                    accept={mimeTypes.map((format) => `${format}, `).join('')}
                />
            </div>
        </TinyFace>
    );
};

export default SelfieCapture;
