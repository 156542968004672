import React, { Fragment } from 'react';
import { Endpoint } from '../../models/prediction';
import { ConfigSubpanelProps } from './ConfigPanel';
import EndpointsSection from './endpoints';
import { ConfigWrapper } from './ConfigWrapper';
import LanguageFieldSection from './languageField/LanguageFieldSection';
import { LANGUAGE_CODE } from '@getyoti/react-face-capture';

// TODO: AITOOL-2804 - Replace this for Object.values(FaceMatchingEndpoints) after the refactor.
const endpointsList = [Endpoint.FaceMatching];

export const FaceMatchingConfigPanel: React.FC<ConfigSubpanelProps> = ({
    config: initialConfig,
    onConfigChange,
    onError,
}) => {
    const onLanguageFieldChange = (value: LANGUAGE_CODE) =>
        onConfigChange({ ...initialConfig, selectedLanguage: value });
    return (
        <>
            <EndpointsSection
                endpointsList={endpointsList}
                config={initialConfig}
                onConfigChange={onConfigChange}
            />
            <LanguageFieldSection
                value={initialConfig.selectedLanguage}
                onChange={onLanguageFieldChange}
            />
            <ConfigWrapper onError={onError} property={'saveImagesFaceMatchingTool'} />
        </>
    );
};
