import React from 'react';
import classNames from 'classnames';
import styles from './subtextColor.module.scss';

interface Props {
    variant?: 'primary' | 'secondary';
    className?: string;
}

const SubtextColor: React.FC<Props> = (props) => {
    const { variant, className, children } = props;

    const color: string = classNames(
        variant === 'secondary' ? styles['subtext-color--secondary'] : styles['subtext-color'],
    );

    return <div className={classNames(color, className)}>{children}</div>;
};

export default SubtextColor;
