import React from 'react';
import ConfigPanel, { ConfigProps } from '../../config/ConfigPanel';
import { DataCollectorConfigPanel } from '../../config/DataCollectorConfigPanel';

interface Props {
    onError: (error: Error) => void;
    configuration: ConfigProps;
    onConfigChange: (config: ConfigProps) => void;
}

const Collector: React.FC<Props> = ({ onError, onConfigChange, configuration }) => {
    return (
        <ConfigPanel>
            <DataCollectorConfigPanel
                config={configuration}
                onConfigChange={onConfigChange}
                onError={onError}
            />
        </ConfigPanel>
    );
};

export default Collector;
