import React from 'react';
import { MetadataField, getMetadataFieldName } from '../../../models/prediction';
import { ConfigSection } from '../ConfigSection';
import RadioButtonList, { RadioButtonEntry } from '../radioButtonsList';

export interface ConfigMetadataProps {
    metadataFieldList: MetadataField[];
    value?: MetadataField;
    onChange: (value?: MetadataField) => void;
    disabled: boolean;
}

const MetadataFieldSection: React.FC<ConfigMetadataProps> = ({
    metadataFieldList,
    value: selectedMetadataField,
    onChange,
    disabled,
}) => {
    const metadataRadioButtonList: RadioButtonEntry<MetadataField>[] = metadataFieldList.map(
        (field) => {
            return { value: field, label: getMetadataFieldName(field) };
        },
    );
    const onMetadataChange = (element: MetadataField) =>
        onChange(element === selectedMetadataField ? undefined : element);

    return (
        <ConfigSection title="Metadata">
            <RadioButtonList
                list={metadataRadioButtonList}
                selectedValue={selectedMetadataField}
                onChange={onMetadataChange}
                disabled={disabled}
            />
        </ConfigSection>
    );
};

export default MetadataFieldSection;
