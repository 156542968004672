import React from 'react';
import Icon from '@yoti/react-components/icon';
import * as iconsList from '@yoti/react-components/icons-list';
import styles from './faceMatchingResponse.module.scss';
import { FaceMatchingResponseData } from '../../../models/prediction';
import classNames from 'classnames';
import { ConfigProps } from '../../config/ConfigPanel';
import Response from './../Response';

interface Props {
    responseData?: FaceMatchingResponseData;
    configuration: ConfigProps;
}

export const FaceMatchingResponse: React.FC<Props> = (props) => {
    const { responseData } = props;

    const { score, errorCode, errorMessage } = responseData || {};

    return (
        <Response errorCode={errorCode} errorMessage={errorMessage}>
            {score && (
                <>
                    <Icon
                        component={iconsList['faceVerified']}
                        className={styles['response__icon']}
                    />
                    <span
                        className={classNames(
                            styles['response__text'],
                            styles['response__text--bold'],
                        )}
                        data-qa="matching-score"
                    >{`Matching score: `}</span>
                    {score}
                </>
            )}
        </Response>
    );
};

export default FaceMatchingResponse;
