import React from 'react';
import { useHistory } from 'react-router-dom';
import { Session } from '../../models/account';
import { LOGIN_PATH } from '../../utils/constants/Routes';

export const banMsg = `You do not have access to the system. Please contact an 
  administrator.`;
export const verifyMsg = `You have been registered in the system. Please, wait
  until your account has been verified by an administrator.`;

const VerifyMsg: React.FC = () => {
    const history = useHistory<Session>();
    const goToLoginPage = () => {
        history.replace(LOGIN_PATH);
    };

    const banned = history.location.state && history.location.state.banned;
    const msg = banned ? banMsg : verifyMsg;

    return (
        <div>
            <p>{msg}</p>
            <button onClick={goToLoginPage} type="button">
                {'< Back'}
            </button>
        </div>
    );
};

export default VerifyMsg;
