import React, { useState } from 'react';
import GlobalContext, { getDefaultValue, Props as GlobalContextProps } from '../../globalContext';

import AlertProvider from '../alert/AlertProvider';

import { OrientationHandler } from '../orientation/OrientationHandler/OrientationHandler';
import { TurnScreen } from '../orientation/TurnScreen/TurnScreen';
import styles from './app.module.scss';
import Router from './Router';
import LocalConfig from './LocalConfig';
import { useFirstLanding } from '../../hooks/firstLanding';

const App: React.FC = () => {
    const firstLandingConfig = useFirstLanding();
    const [globalContext, setGlobalContext] = useState<GlobalContextProps>(
        getDefaultValue(firstLandingConfig),
    );

    const [isTinyFaceReady, setIsTinyFaceReady] = useState<boolean>(false);

    return (
        <GlobalContext.Provider
            value={{
                ...globalContext,
                set: setGlobalContext,
                isTinyFaceReady,
                setIsTinyFaceReady,
            }}
        >
            <LocalConfig>
                <AlertProvider>
                    <OrientationHandler alternativeComponent={<TurnScreen />}>
                        <div className={styles['app']}>
                            <Router />
                        </div>
                    </OrientationHandler>
                </AlertProvider>
            </LocalConfig>
        </GlobalContext.Provider>
    );
};

export default App;
