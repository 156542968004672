import React from 'react';
import Icon from '@yoti/react-components/icon';
import * as iconsList from '@yoti/react-components/icons-list';
import styles from './response.module.scss';
import classNames from 'classnames';

interface Props {
    errorCode?: string;
    errorMessage?: string;
}

const Response: React.FC<Props> = (props) => {
    const { children, errorCode, errorMessage } = props;

    return (
        <div className={styles['response']}>
            <div>
                {errorCode ? (
                    <div
                        className={classNames(
                            styles['response__container'],
                            styles['response__element'],
                        )}
                        data-qa={'error-result'}
                    >
                        <Icon
                            component={iconsList['clear']}
                            className={styles['response__element__icon-error']}
                        />
                        <span>{errorMessage}</span>
                    </div>
                ) : (
                    <div className={styles['response__container']}>
                        {children &&
                            React.Children.map(
                                children,
                                (r, i) =>
                                    !!r && (
                                        <div
                                            className={styles['response__element']}
                                            data-qa={'children'}
                                            key={i}
                                        >
                                            {r}
                                        </div>
                                    ),
                            )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Response;
