import { ALERT_TYPES } from '@yoti/react-components/alert';
import React, { useCallback, useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import AccountService from '../../api/account/account';
import { providers } from '../../api/account/factory';
import GlobalContext from '../../globalContext';
import { useNotify, useQuery } from '../../hooks';
import { LoginSettings, LoginType } from '../../models/account';
import Config from '../../utils/config';
import { ROOT_PATH } from '../../utils/constants/Routes';
import { HeaderTab } from '../commons';
import LoadingCircular from './../loading';
import GoogleButton from './Google/Button';
import style from './login.module.scss';
import YotiButton from './Yoti/Button';

const accountService = new AccountService(Config.apiUrl);

const Login: React.FC = () => {
    const account = useContext(GlobalContext);
    const [loginCfg, setLoginCfg] = React.useState<LoginSettings>();
    const notify = useNotify();
    const params = useQuery();

    const login = useCallback(
        (loginType: LoginType) => {
            providers[loginType].begin(params, Config.env).catch((error) =>
                notify({
                    message: error.message,
                    type: ALERT_TYPES.DANGER,
                    icon: true,
                }),
            );
        },
        [notify, params],
    );

    const accountToken = (account as any)?.token;
    const hasConfig = !!loginCfg;
    useEffect(() => {
        if (accountToken) {
            return;
        }
        if (hasConfig) {
            return;
        }
        let isCancelled = false;
        accountService
            .loginCfg()
            .then((cfg) => {
                if (!isCancelled) {
                    setLoginCfg(cfg);
                }
            })
            .catch((error) => {
                notify({
                    message: error.message,
                    type: ALERT_TYPES.DANGER,
                    icon: true,
                });
            });
        return () => {
            isCancelled = true;
        };
    }, [accountToken, hasConfig, notify]);

    if (accountToken) {
        return <Redirect to={ROOT_PATH} />;
    }
    if (!loginCfg) {
        return <LoadingCircular />;
    }
    return (
        <div className={style.login}>
            <HeaderTab tabs={['Login']}></HeaderTab>
            <div className={style.login__panel}>
                <p>Login in to your account:</p>
                <br />
                <GoogleButton onClick={() => login(LoginType.Google)} />
                {loginCfg.yotiCfg && loginCfg.yotiCfg.clientSdkId && loginCfg.yotiCfg.scenarioId && (
                    <YotiButton
                        clientSdkId={loginCfg.yotiCfg.clientSdkId}
                        scenarioId={loginCfg.yotiCfg.scenarioId}
                        text="Sign in with Yoti"
                        env={Config.env}
                        onDebugButtonClick={() => login(LoginType.Yoti)}
                        onTokenReceived={(token) => {
                            params.append('token', token);
                            login(LoginType.Yoti);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default Login;
