import './tab-bar.scss';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as VARIATIONS from './tab-variation';

export function TabBar({ children, variation }) {
  const rootClasses = classnames({
    'yoti-tabs-bar': true,
    'yoti-tabs-bar--fluid': variation === VARIATIONS.FLUID,
  });

  return (
    <div className={rootClasses} style={{ '--yoti-tabs-number': React.Children.count(children) }}>
      {children}
    </div>
  );
}

TabBar.defaultProps = {
  children: '',
  variation: VARIATIONS.FIXED,
};

TabBar.propTypes = {
  children: PropTypes.node,
  variation: PropTypes.string,
};
