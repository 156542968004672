import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Session } from '../../models/account';
import { SESSION_KEY } from '../../utils/constants/storage';

export interface Redirection {
    should: boolean;
    path: string;
}

interface RedirectProps {
    shouldRedirect: (account: Session) => Redirection;
}

function parseJSON(json: string | null): any {
    if (json) {
        try {
            return JSON.parse(json);
        } catch {
            return null;
        }
    }
    return null;
}

const Redirect: React.FC<RedirectProps> = (props) => {
    const { shouldRedirect } = props;
    const history = useHistory<Session>();

    useEffect(() => {
        const sessionData = localStorage.getItem(SESSION_KEY);
        const account = parseJSON(sessionData) as Session;
        const redirect = shouldRedirect(account);
        if (redirect.should) history.replace(redirect.path);
    }, [shouldRedirect, history]);

    return <>{props.children}</>;
};

export default Redirect;
