import { Alert, ALERT_SIZES, ALERT_TYPES } from '@yoti/react-components/alert';
import React from 'react';
import styles from './alert.module.scss';

const CookiesAlert: React.FC = () => {
    return (
        <div className={styles['cookiesAlert']}>
            <Alert size={ALERT_SIZES.LARGE} heading="Cookies" type={ALERT_TYPES.DANGER} icon>
                <span data-qa="msg-text">{`We can't use your local storage to save the configuration, please enable it to save the configuration in multiple retries.`}</span>
            </Alert>
        </div>
    );
};

export default CookiesAlert;
