import './radio-buttons.css';
import React from 'react';
import { PropTypes } from 'prop-types';
import RadioButton from './radio-button';

const RadioButtons = ({ selectedValue, options, disabled, 'data-qa': dataQA, onChange }) => {
  return (
    <div className="yoti-radio-buttons" data-qa={dataQA}>
      <div className="yoti-radio-buttons__list" role="radiogroup">
        {options.map(({ label, value, 'data-qa': dataQaChild }) => (
          <RadioButton
            key={value}
            selectedValue={selectedValue}
            label={label}
            value={value}
            disabled={disabled}
            onChange={onChange}
            data-qa={dataQaChild}
          />
        ))}
      </div>
    </div>
  );
};

RadioButtons.defaultProps = {
  selectedValue: undefined,
  options: [],
  disabled: false,
  'data-qa': 'radio-buttons',
};

RadioButtons.propTypes = {
  selectedValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      ...RadioButton.propTypes,
    })
  ),
  disabled: PropTypes.bool,
  'data-qa': PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default RadioButtons;
