import React, { useEffect, useState } from 'react';
import { ConfigSection } from './ConfigSection';
import Checkbox from '@yoti/react-components/checkbox';
import useApi from './../../hooks/useApi';
import { buildUrl } from '../../utils/url';
import Config from '../../utils/config';
import { User } from './../../models/account';
import { getBooleanProperty, UserBooleanField } from './../../utils/types';
import styles from './imageConfigPanel.module.scss';
import { useLogOut } from '../../hooks/useLogOut/useLogOut';

interface Props {
    property: UserBooleanField;
    onError: (error: Error) => void;
}

const ImageConfigPanel: React.FC<Props> = ({ property, onError }) => {
    const [user, setUser] = useState<User>();

    const apiRes = useApi<User>(buildUrl(Config.apiUrl, 'account-info'), {
        hideErrorNotification: () => true,
        auto: true,
    });
    const { data, call, error } = apiRes;
    const [saveImage, setSaveImage] = useState<boolean>(false);

    useLogOut(error, onError);

    useEffect(() => {
        if (data) {
            setUser(data);
            setSaveImage(getBooleanProperty(data, property));
        }
    }, [data, property]);

    const handleChange = () => {
        if (!user && error) {
            onError(error);
            return;
        } else if (!user && !error) {
            return;
        }
        call({
            method: 'PUT',
            data: { ...user, [property]: !saveImage },
        });
    };

    return (
        <ConfigSection title="Image">
            <Checkbox checked={saveImage} onChange={handleChange} label="Store images" />
            <p className={styles['image-config-panel__message']}>
                Our R&D team will only use the images for testing. The images will be deleted once
                they have served their purpose.
            </p>
        </ConfigSection>
    );
};

export default ImageConfigPanel;
