import { Login, LoginType } from '../../models/account';
import Config from '../../utils/config';
import { getLocation } from '../../utils/redirect';
import { NodeEnv } from '../../utils/types';
import { REDIRECT_QUERY_STRING_PARAM } from '../base';
import AccountService from './account';

const accountService = new AccountService(Config.apiUrl);

const handleModalQR = (query: URLSearchParams): Promise<void> => {
    const token = query.get('token');
    let url = `/auth/${LoginType.Yoti}/callback?token=${token}`;

    const r = query.get('r');
    if (r) {
        url += `&${REDIRECT_QUERY_STRING_PARAM}=${r}`;
    }

    window.location.replace(url);
    return Promise.resolve();
};

const handleYotiPageQR = (query: URLSearchParams): Promise<void> => {
    const r = query.get(REDIRECT_QUERY_STRING_PARAM);

    return accountService.login(LoginType.Yoti, r || undefined).then((res) => {
        window.location.replace(res.url);
    });
};

export const begin = (query: URLSearchParams, env: NodeEnv): Promise<void> => {
    const handler = env !== 'production' ? handleYotiPageQR : handleModalQR;
    return handler(query);
};

export const callback = (query: URLSearchParams): Login => {
    const token = query.get('token') || '';
    const redirectToken = query.get(REDIRECT_QUERY_STRING_PARAM);

    const redirect = redirectToken ? getLocation(redirectToken) : undefined;

    return {
        type: LoginType.Yoti,
        data: { token },
        redirect: redirect,
    };
};
