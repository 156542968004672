import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './icon.scss';

function IconPlaceholder({ ...rest }) {
  return <span data-qa="yoti-icon-placeholder" {...rest} />;
}

function Icon({ component, size, color, className: extraClassName, onClick }) {
  const IconComponent = component;

  const getIconProps = (iconClass) => ({
    className: classNames(iconClass, extraClassName),
    style: { fontSize: size, color },
    onClick,
  });

  return (
    <Suspense fallback={<IconPlaceholder {...getIconProps('yoti-icon-placeholder')} />}>
      <IconComponent data-qa="icon" {...getIconProps('yoti-icon')} />
    </Suspense>
  );
}

Icon.defaultProps = {
  size: null,
  color: null,
  className: '',
  onClick: () => {},
};

Icon.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired /* functional component and lazy component */,
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Icon;
