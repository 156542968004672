import React from 'react';
import { AgeAntispoofingConfigPanel } from '../../config/AgeAntispoofingConfigPanel';
import ConfigPanel, { ConfigProps } from '../../config/ConfigPanel';

interface Props {
    onError: (error: Error) => void;
    configuration: ConfigProps;
    onConfigChange: (config: ConfigProps) => void;
}

const Settings: React.FC<Props> = ({ onError, onConfigChange, configuration }) => {
    return (
        <ConfigPanel>
            <AgeAntispoofingConfigPanel
                config={configuration}
                onConfigChange={onConfigChange}
                onError={onError}
            />
        </ConfigPanel>
    );
};

export default Settings;
