import React, { Fragment } from 'react';
import { RadioButton } from '@yoti/react-components/radio-buttons';
import styles from './radioButtonList.module.scss';

export interface RadioButtonEntry<T> {
    value: T;
    label: string;
    subConfig?: JSX.Element;
}

export interface RadioButtonListProps<T> {
    list: RadioButtonEntry<T>[];
    selectedValue?: T;
    onChange: (element: T) => void;
    disabled?: boolean;
}

const RadioButtonList = <T extends {}>(props: RadioButtonListProps<T>) => {
    const { list, selectedValue, onChange, disabled } = props;
    return (
        <div className={styles['radio-buttons']} data-qa="radio-buttons-container">
            {list.map((entry, i) => {
                return (
                    <Fragment key={i}>
                        <RadioButton
                            disabled={disabled}
                            selectedValue={selectedValue}
                            label={entry.label}
                            value={entry.value}
                            onChange={() => {
                                onChange(entry.value);
                            }}
                        />
                        {entry.value === selectedValue && entry.subConfig}
                    </Fragment>
                );
            })}
        </div>
    );
};

export default RadioButtonList;
