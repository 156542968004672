import { ImageDimension, ImageValidation } from '../models/image';

/**
 * Returns the image resolution dimensions in pixels.
 * @param image An image base64 string.
 */
const getImageDimensions = async (image: string): Promise<ImageDimension> => {
    return new Promise(function (resolved) {
        const htmlImage = new Image();
        htmlImage.onload = function () {
            resolved({ width: htmlImage.width, height: htmlImage.height });
        };
        htmlImage.src = image;
    });
};

/**
 * Returns an array with errors caused for not fulfilling validation conditions or empty if none.
 * @param image An image base64 string.
 * @param imageValidations A collection of validations that have to be fulfilled
 * @param imageValidations.maxWidth Max image width allowed
 * @param imageValidations.minWidth Min image width allowed
 * @param imageValidations.maxHeight Max image height allowed
 * @param imageValidations.minHeight Min image height allowed
 */
export const validateImageDimensions = async (
    image: string,
    imageValidations: ImageValidation,
): Promise<Error[]> => {
    const imgDimensions = await getImageDimensions(image);

    const errors: Error[] = [];

    const { maxWidth, minWidth, maxHeight, minHeight } = imageValidations;

    if (imgDimensions.width > maxWidth) {
        errors.push(new Error(`Image width too large. Max width ${maxWidth}.`));
    } else if (imgDimensions.width < minWidth) {
        errors.push(new Error(`Image width too small. Min width ${minWidth}.`));
    }

    if (imgDimensions.height > maxHeight) {
        errors.push(new Error(`Image height too large. Max height ${maxHeight}.`));
    } else if (imgDimensions.height < minHeight) {
        errors.push(new Error(`Image height too small. Min height ${minHeight}.`));
    }

    return errors;
};
