import React from 'react';
import { TabHeader, TabBar, TAB_VARIATIONS } from '@yoti/react-components/tabs';
import styles from './headerTab.module.scss';
import '@yoti/react-components/tabs/tabs.css';

interface Props {
    tabs: string[];
    onClick?: (tab: string) => void;
    selected?: string;
    className?: string;
}

const HeaderTab: React.FC<Props> = (props) => {
    const { tabs, selected, onClick, className } = props;

    const selectedTab = selected ? selected : tabs[0];

    const selectHeader = (header: string) => {
        if (onClick) {
            onClick(header);
        }
    };

    return (
        <div className={tabs.length === 1 ? `${styles['single-tab']} ${className}` : className}>
            <TabBar variation={TAB_VARIATIONS.FLUID}>
                {tabs.map((header, i) => {
                    return (
                        <TabHeader
                            key={i}
                            isSelected={header === selectedTab}
                            onClick={() => selectHeader(header)}
                            title={header}
                        />
                    );
                })}
            </TabBar>
        </div>
    );
};

export default HeaderTab;
