import React from 'react';
import ConfigPanel, { ConfigProps } from '../../config/ConfigPanel';
import { FaceMatchingConfigPanel } from '../../config/FaceMatchingConfigPanel';

interface Props {
    className?: string;
    onError: (error: Error) => void;
    configuration: ConfigProps;
    onConfigChange: (config: ConfigProps) => void;
}

const Settings: React.FC<Props> = ({ className, onError, onConfigChange, configuration }) => {
    return (
        <div className={className}>
            <ConfigPanel>
                <FaceMatchingConfigPanel
                    config={configuration}
                    onConfigChange={onConfigChange}
                    onError={onError}
                />
            </ConfigPanel>
        </div>
    );
};

export default Settings;
