import React from 'react';
import Icon from '@yoti/react-components/icon';
import * as iconsList from '@yoti/react-components/icons-list';
import styles from '../ageAntispoofingResponse.module.scss';
import classNames from 'classnames';
import { RawAgeDisplayer } from './raw-age-displayer/RawAgeDisplayer';
import {
    RangeAgeDisplayerProps,
    Interval,
    RangeAgeDisplayer,
} from './range-age-displayer/RangeAgeDisplayer';

export interface Props extends Omit<RangeAgeDisplayerProps, 'interval'> {
    agePredictionIsValid: boolean;
    interval?: Interval;
}

export const AgeDisplayer: React.FC<Props> = ({ agePredictionIsValid, age, interval }) => (
    <>
        <Icon component={iconsList['ageEstimation']} className={styles['response__icon']} />
        <span className={classNames(styles['response__text'], styles['response__text--bold'])}>
            {'Age estimation:'}
        </span>
        {agePredictionIsValid ? (
            interval ? (
                <RangeAgeDisplayer age={age} interval={interval} data-qa="range-age-displayer" />
            ) : (
                <RawAgeDisplayer age={age} data-qa="raw-age-displayer" />
            )
        ) : (
            <span
                className={classNames(
                    styles['response__text'],
                    styles['response__text--undetermined'],
                )}
                data-qa="no-age-displayer"
            >
                Undetermined*
            </span>
        )}
    </>
);
