import { ERROR_CODE, FCMPayload, LANGUAGE_CODE } from '@getyoti/react-face-capture';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useApi } from '../../../hooks';
import { useLogOut } from '../../../hooks/useLogOut/useLogOut';
import { User } from '../../../models/account';
import Config from '../../../utils/config';
import { buildUrl } from '../../../utils/url';
import FaceCaptureModule from '../../face-capture-module';
import { formattedSupportedBrowsersList } from '../../face-capture-module/FaceCaptureModule';
import { FCMErrorHandler } from '../../fcm-error-handler/FCMErrorHandler';
import styles from './scanTab.module.scss';

const initialUser: User = {
    banned: false,
    email: '',
    id: 0,
    roleId: 0,
    signUpDate: 0,
    latestLogin: 0,
    verified: false,
    logsNumber: 0,
    requestsPerDay: 0,
    requestsPerDayLimit: 0,
    requestsInTotal: 0,
    requestsInTotalLimit: 0,
    saveImages: false,
    saveImagesFaceMatchingTool: false,
    saveImagesScanTool: false,
};

export interface ScanState {
    user: User;
    fcmError?: string;
}

export const defaultState: ScanState = {
    user: initialUser,
};

export const getErrorMessage = (errorCode: string) => {
    switch (errorCode) {
        case ERROR_CODE.NO_CAMERA_PERMISSION:
            return 'Cannot load the webcam. Make sure your browser has permissions to access the camera.';
        case ERROR_CODE.NO_CAMERA:
            return 'Camera not found. Make sure your camera is available.';
        case ERROR_CODE.UNSUPPORTED_BROWSER:
            return `The current browser is not supported, please use one of the following: ${formattedSupportedBrowsersList}.`;
        case ERROR_CODE.OVERCONSTRAINED:
        case ERROR_CODE.GENERIC_CAMERA_ERROR:
            return 'Cannot load the webcam because it is not compatible with the application. Try using a different one.';
        case ERROR_CODE.FACE_DETECTION_INIT_ERROR:
            return 'Initialization failed. Try refreshing the application.';
        case ERROR_CODE.SECURE_SESSION_EXPIRED:
            return 'The secure session has expired. Try refreshing the application.';
        case ERROR_CODE.INTERNAL_ERROR:
        default:
            return 'There was a problem. Try refreshing the application or contact support.';
    }
};

interface Props {
    language: LANGUAGE_CODE;
    onSuccess: (res: FCMPayload, base64ImagePreview?: string) => void;
    onError: (error: Error) => void;
}

const ScanTab: React.FC<Props> = ({ language, onSuccess, onError }) => {
    const [state, setState] = useState<ScanState>(defaultState);
    const { user, fcmError } = state;

    const onFcmError = useCallback(
        (error: Error, code: string) => {
            onError(error);
            setState((prev) => ({ ...prev, fcmError: getErrorMessage(code) }));
        },
        [onError, setState],
    );

    const onFcmReset = useCallback(() => setState((prev) => ({ ...prev, fcmError: undefined })), [
        setState,
    ]);

    // This variable is true if the limit of total requests is undefined and the limit of daily
    // requests is defined. This is also true if both limits are not undefined and the difference between
    // the limit of daily requests and the requests done in the current day is not greater
    // than the same diffence between the total ones.
    const showDailyRequests =
        (user.requestsPerDayLimit &&
            user.requestsInTotalLimit &&
            !(
                user.requestsPerDayLimit - user.requestsPerDay >
                user.requestsInTotalLimit - user.requestsInTotal
            )) ||
        (user.requestsPerDayLimit && !user.requestsInTotalLimit);

    const pendingDailyRequests = user.requestsPerDayLimit - user.requestsPerDay;
    const pendingTotalRequests = user.requestsInTotalLimit - user.requestsInTotal;

    const apiRes = useApi<User>(buildUrl(Config.apiUrl, 'account-info'), {
        hideErrorNotification: () => true,
        auto: true,
    });
    const { data, error } = apiRes;

    useLogOut(error, onError);

    useEffect(() => {
        if (data) {
            setState((prev) => ({ ...prev, user: data }));
        }
    }, [data]);

    return (
        <FCMErrorHandler errorMessage={fcmError || ''} onReset={onFcmReset}>
            <div>
                <FaceCaptureModule onSuccess={onSuccess} onError={onFcmError} language={language} />
                {!!showDailyRequests && (
                    <div
                        data-qa="daily-requests"
                        className={classNames(
                            styles['scan_tab__user_requests'],
                            styles['scan_tab__user_requests--daily'],
                        )}
                    >
                        <span>{`Daily requests left: ${
                            pendingDailyRequests < 0 ? 0 : pendingDailyRequests
                        }`}</span>
                    </div>
                )}
                {!!user.requestsInTotalLimit && (
                    <div
                        data-qa="total-requests"
                        className={classNames(
                            styles['scan_tab__user_requests'],
                            styles['scan_tab__user_requests--total'],
                        )}
                    >
                        <span>{`Total requests left: ${
                            pendingTotalRequests < 0 ? 0 : pendingTotalRequests
                        }`}</span>
                    </div>
                )}
            </div>
        </FCMErrorHandler>
    );
};

export default ScanTab;
