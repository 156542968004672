import { useCallback, useMemo } from 'react';

function parseJSON<T>(json: any): T | null {
    try {
        const typedValue = JSON.parse(json) as T;
        return typedValue;
    } catch {
        return null;
    }
}

export function useGetLocalStorage<T>(
    key: string,
    typeGuard?: (value: any) => value is T,
): T | null {
    const checkValue = useCallback(() => {
        const localStorageValue = localStorage.getItem(key);
        if (localStorageValue !== null) {
            const typedValue = parseJSON<T>(localStorageValue);
            if (typeGuard !== undefined) {
                if (typeGuard(typedValue)) return typedValue;
            } else {
                return typedValue;
            }
        }
        return null;
    }, [key, typeGuard]);

    const value = useMemo(checkValue, [checkValue]);

    return value;
}
