import { createContext } from 'react';
import { AlertProps } from './Alert';

export interface Props {
    notify: (props: AlertProps) => void;
}

export const defaultValue: Props = {
    notify: () => {}, // Mock method to implement 'Props'.
};

export default createContext(defaultValue);
