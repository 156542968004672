import { useEffect, useState } from 'react';
import { Interval } from '../../components/result/age-antispoofing/age-displayer/range-age-displayer/RangeAgeDisplayer';
import { useGlobalContext } from '../../globalContext';
import { AgeDisplayMode } from '../../models/prediction';
import Config from '../../utils/config';

export const useAgeRangeInterval = (): Interval | undefined => {
    const {
        scanConfiguration: { selectedAgeDisplayMode },
    } = useGlobalContext();
    const ageDisplayInterval = Config.defaultConfig.ageDisplayInterval;
    const [interval, setInterval] = useState<Interval | undefined>(ageDisplayInterval);

    useEffect(() => {
        setInterval(
            selectedAgeDisplayMode === AgeDisplayMode.Range ? ageDisplayInterval : undefined,
        );
    }, [selectedAgeDisplayMode, ageDisplayInterval]);

    return interval;
};
