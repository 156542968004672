import React, { ChangeEventHandler } from 'react';
import { LANGUAGE_CODE } from '@getyoti/react-face-capture';
import { ConfigSection } from '../ConfigSection';
import SelectField from '@yoti/react-components/select-field';
import '@yoti/react-components/select-field/select-field.css';

interface Props {
    value: LANGUAGE_CODE;
    onChange: (value: LANGUAGE_CODE) => void;
}

const languageOptions = Object.values(LANGUAGE_CODE).map((language) => ({
    value: language,
    label: language,
}));

const LanguageFieldSection: React.FC<Props> = ({ value, onChange }) => {
    const handleChange: ChangeEventHandler<HTMLSelectElement> = ({ target: { value } }) => {
        onChange(value as LANGUAGE_CODE);
    };

    return (
        <ConfigSection title="Language">
            <SelectField
                name="withValue"
                options={languageOptions}
                label="Language"
                selectedValue={value}
                onChange={handleChange}
            />
        </ConfigSection>
    );
};

export default LanguageFieldSection;
