import React from 'react';
import Icon from '@yoti/react-components/icon';
import * as iconsList from '@yoti/react-components/icons-list';
import styles from './resultCard.module.scss';
import classNames from 'classnames';

export enum ResultCardVariant {
    Bottom,
    Right,
}

interface Props {
    // content represents the main field of the card that is always visible.
    content: JSX.Element;
    // secondaryContent represents the optional colapsable content.
    secondaryContent?: JSX.Element;
    className?: string;
    open?: boolean;
    variant?: ResultCardVariant;
}

const ResultCard: React.FC<Props> = (props) => {
    const { content, secondaryContent, open: openByDefault, className, variant } = props;
    const [open, setOpen] = React.useState<boolean>(!!openByDefault);

    const onClick = () => {
        setOpen(!open);
    };

    const rightVariant = variant === ResultCardVariant.Right;

    return (
        <div className={className}>
            <div>
                <div
                    className={classNames(styles['card__inner-container'], {
                        [styles['card__inner-container--right']]: rightVariant,
                    })}
                >
                    <div
                        className={classNames(styles['card__inner-container__primary-content'], {
                            [styles['card__inner-container__primary-content--right']]: rightVariant,
                            [styles[
                                'card__inner-container__primary-content--no-secondary-content'
                            ]]: !secondaryContent,
                        })}
                    >
                        {content}
                    </div>
                    {!!secondaryContent && (
                        <>
                            {open && (
                                <div className={styles['card__inner-container__secondary-content']}>
                                    {secondaryContent}
                                </div>
                            )}
                            <div
                                className={classNames(styles['card__inner-container__handler'], {
                                    [styles['card__inner-container__handler--right']]: rightVariant,
                                })}
                                role="button"
                                tabIndex={0}
                                onClick={onClick}
                                onKeyDown={onClick}
                                data-qa="handler"
                            >
                                {open ? (
                                    <Icon
                                        component={
                                            iconsList[rightVariant ? 'chevronLeft' : 'chevronUp']
                                        }
                                    />
                                ) : (
                                    <Icon
                                        component={
                                            iconsList[rightVariant ? 'chevronRight' : 'chevronDown']
                                        }
                                    />
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ResultCard;
