import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Callback, Login, VerifyMsg } from '../account';
import Home from '../home';
import ScanPanel from '../scan-panel';
import NotFound from './NotFound';
import Route from './Route';
import LayOut from './LayOut';
import Config from './../../utils/config';
import FaceMatchingPanel from '../face-matching-panel';
import Redirect, { Redirection } from './Redirect';
import { Session } from '../../models/account';
import {
    AGE_ANTISPOOFING_PATH,
    CALLBACK_PATH,
    FACE_MATCHING_PATH,
    LOGIN_PATH,
    ROOT_PATH,
    VERIFY_ACCOUNT_PATH,
} from '../../utils/constants/Routes';

function loginRedirection(account: Session): Redirection {
    const should = !!account?.token && account?.verified && !account?.banned;
    return { should, path: ROOT_PATH };
}

function verifyRedirection(account: Session): Redirection {
    if (!account) {
        return { should: true, path: LOGIN_PATH };
    }
    const should = !!account.token && !!account.verified && !account.banned;
    return { should, path: ROOT_PATH };
}

const Router: React.FC = () => {
    return (
        <BrowserRouter>
            <LayOut>
                <Switch>
                    <Route
                        path={ROOT_PATH}
                        exact
                        component={Config.showFaceMatching ? Home : ScanPanel}
                    />
                    <Route path={AGE_ANTISPOOFING_PATH} exact component={ScanPanel} />
                    {Config.showFaceMatching && (
                        <Route path={FACE_MATCHING_PATH} exact component={FaceMatchingPanel} />
                    )}
                    <Route path={LOGIN_PATH} exact anonymous>
                        <Redirect shouldRedirect={loginRedirection}>
                            <Login />
                        </Redirect>
                    </Route>
                    <Route path={CALLBACK_PATH} anonymous component={Callback} />
                    <Route path={VERIFY_ACCOUNT_PATH} anonymous>
                        <Redirect shouldRedirect={verifyRedirection}>
                            <VerifyMsg />
                        </Redirect>
                    </Route>
                    <Route path="*" anonymous component={NotFound} />
                </Switch>
            </LayOut>
        </BrowserRouter>
    );
};

export default Router;
