import { LocationDescriptor } from 'history';

// invalidSessionError is the error code the backend will return in case there is
// a problem with the current session.
export const invalidSessionError = 'INVALID_SESSION_ERROR';

export interface Session {
    exp: number;
    token: string;
    userId: number;
    role: RoleCode;
    verified: boolean;
    banned: boolean;
}

export interface User {
    id: number;
    email: string;
    verified: boolean;
    banned: boolean;
    roleId: number;
    signUpDate: number;
    latestLogin: number;
    logsNumber: number;
    requestsPerDay: number;
    requestsPerDayLimit: number;
    requestsInTotal: number;
    requestsInTotalLimit: number;
    saveImages: boolean;
    saveImagesFaceMatchingTool: boolean;
    saveImagesScanTool: boolean;
}

export enum RoleCode {
    DataCollector = 'datacollector',
    Reviewer = 'reviewer',
    User = 'user',
    Tester = 'tester',
    Admin = 'admin',
}

interface YotiCfg {
    clientSdkId?: string;
    scenarioId?: string;
}

export interface LoginSettings {
    yotiCfg?: YotiCfg;
}

export enum LoginType {
    Google = 'google',
    Yoti = 'yoti',
}

export interface GoogleLogin {
    type: LoginType.Google;
    url: string;
    state: string;
}

export interface GoogleState {
    token: string;
    redirect: string;
}

export interface YotiLogin {
    type: LoginType.Yoti;
    url: string;
}

export type LoginResponse<T extends LoginType> = T extends LoginType.Google
    ? GoogleLogin
    : YotiLogin;

export type Login =
    | {
          type: LoginType.Google;
          redirect?: LocationDescriptor;
          data: GoogleToken;
      }
    | {
          type: LoginType.Yoti;
          redirect?: LocationDescriptor;
          data: YotiToken;
      };

export interface YotiToken {
    token: string;
}

interface GoogleToken {
    code: string;
    state: string;
    storedState: string;
}
