/**
 * Join the base and path to build an url.
 * @param base The base url.
 * @param path The path.
 * @returns The result url.
 */
export const buildUrl = (base: string, path: string) => {
    if (path.startsWith('/')) {
        path = path.substr(1);
    }
    if (!base.endsWith('/')) {
        base = `${base}/`;
    }
    return `${base}${path}`;
};
