import React from 'react';
import {
    AgeDisplayMode,
    AgeEstimationModelField,
    Endpoint,
    LevelOfAssuranceField,
    MetadataField,
    OperatorField,
    Sections,
    SecureMode,
    validRoleConfig,
} from '../../models/prediction';
import { Config as ConsentConfig } from '../consent';
import { ConfigSubpanelProps } from './ConfigPanel';
import { ConfigWrapper } from './ConfigWrapper';
import EndpointsSection from './endpoints';
import LevelOfAssuranceFieldSection from './levelOfAssurance';
import MetadataFieldSection from './metadataField';

import { LANGUAGE_CODE } from '@getyoti/react-face-capture';
import { useGlobalContext } from '../../globalContext';
import Config from '../../utils/config';
import AgeDisplayModeSection from './ageDisplayMode/AgeDisplayModeSection';
import AgeEstimationModelSection from './ageEstimationModelField/AgeEstimationModelSection';
import LanguageFieldSection from './languageField/LanguageFieldSection';
import OperatorFieldSection from './operator/OperatorFieldSection';
import SecureModeSection from './secure/SecureModeSection';
import ThresholdFieldSection from './threshold/ThresholdFieldSection';

// TODO: AITOOL-2804 - Replace this for Object.values(AgeAntispoofingEndpoints) after the refactor.
const endpointsList = [
    Endpoint.Antispoofing,
    Endpoint.Age,
    Endpoint.AgeAntispoofing,
    Endpoint.AgeAntispoofingVerify,
    Endpoint.AgeVerify,
    Endpoint.SelfCheckoutAntiSpoofing,
];
const metadataFieldsList = Object.values(MetadataField);
const ageEstimationModelFieldsList = Object.values(AgeEstimationModelField);
const levelOfAssuranceFieldList = Config.levelAssuranceValues;
const operatorFieldList = Object.values(OperatorField);
const secureModeList = Object.values(SecureMode);
const ageDisplayModeList = Object.values(AgeDisplayMode);

export const AgeAntispoofingConfigPanel: React.FC<ConfigSubpanelProps> = ({
    config: initialConfig,
    onConfigChange,
    onError,
}) => {
    const { account } = useGlobalContext();
    const currentRole = account?.role;
    const onLevelOfAssuranceChange = (element?: LevelOfAssuranceField) =>
        onConfigChange({
            ...initialConfig,
            selectedLevelOfAssuranceField:
                element === initialConfig.selectedLevelOfAssuranceField ? undefined : element,
        });
    const onAgeEstimationModelChange = (element?: AgeEstimationModelField) =>
        onConfigChange({
            ...initialConfig,
            selectedAgeEstimationModelField:
                element === initialConfig.selectedAgeEstimationModelField ? undefined : element,
        });
    const onMetadataChange = (element?: MetadataField) =>
        onConfigChange({
            ...initialConfig,
            selectedMetadataField:
                element === initialConfig.selectedMetadataField ? undefined : element,
        });

    const onOperatorChange = (element?: OperatorField) => {
        onConfigChange({
            ...initialConfig,
            selectedOperatorField:
                element === initialConfig.selectedOperatorField ? undefined : element,
        });
    };
    const onThresholdChange = (element?: number) => {
        onConfigChange({
            ...initialConfig,
            selectedThresholdField:
                element === initialConfig.selectedThresholdField ? undefined : element,
        });
    };
    const onSecureModeChange = (value: SecureMode) =>
        onConfigChange({
            ...initialConfig,
            selectedSecureMode: value,
        });
    const onAgeDisplayModeChange = (value: AgeDisplayMode) =>
        onConfigChange({ ...initialConfig, selectedAgeDisplayMode: value });
    const onLanguageFieldChange = (value: LANGUAGE_CODE) =>
        onConfigChange({ ...initialConfig, selectedLanguage: value });
    const onConsentChange = (consentGiven: boolean) =>
        onConfigChange({ ...initialConfig, consent: consentGiven });
    return (
        <>
            {validRoleConfig(Sections.Endpoint, currentRole) && (
                <>
                    <EndpointsSection
                        endpointsList={endpointsList}
                        config={initialConfig}
                        onConfigChange={onConfigChange}
                    />
                </>
            )}
            {validRoleConfig(Sections.Metadata, currentRole) && (
                <>
                    <MetadataFieldSection
                        metadataFieldList={metadataFieldsList}
                        value={initialConfig.selectedMetadataField}
                        onChange={onMetadataChange}
                        disabled={
                            initialConfig.selectedEndpoint === Endpoint.SelfCheckoutAntiSpoofing
                        }
                    />
                </>
            )}
            {validRoleConfig(Sections.AgeEstimationModel, currentRole) && (
                <>
                    <AgeEstimationModelSection
                        list={ageEstimationModelFieldsList}
                        value={initialConfig.selectedAgeEstimationModelField}
                        onChange={onAgeEstimationModelChange}
                        disabled={
                            initialConfig.selectedEndpoint === Endpoint.Antispoofing ||
                            initialConfig.selectedEndpoint === Endpoint.SelfCheckoutAntiSpoofing ||
                            initialConfig.selectedEndpoint === Endpoint.FaceMatching
                        }
                    />
                </>
            )}
            {validRoleConfig(Sections.LevelOfAssurance, currentRole) && (
                <>
                    <LevelOfAssuranceFieldSection
                        levelOfAssuranceFieldList={levelOfAssuranceFieldList}
                        value={initialConfig.selectedLevelOfAssuranceField}
                        onChange={onLevelOfAssuranceChange}
                        disabled={
                            initialConfig.selectedEndpoint === Endpoint.Age ||
                            initialConfig.selectedEndpoint === Endpoint.AgeVerify ||
                            initialConfig.selectedEndpoint === Endpoint.SelfCheckoutAntiSpoofing
                        }
                    />
                </>
            )}
            {validRoleConfig(Sections.Operator, currentRole) && (
                <OperatorFieldSection
                    operatorFieldList={operatorFieldList}
                    value={initialConfig.selectedOperatorField}
                    onChange={onOperatorChange}
                    disabled={
                        initialConfig.selectedEndpoint !== Endpoint.AgeVerify &&
                        initialConfig.selectedEndpoint !== Endpoint.AgeAntispoofingVerify
                    }
                />
            )}
            {validRoleConfig(Sections.Threshold, currentRole) && (
                <ThresholdFieldSection
                    value={initialConfig.selectedThresholdField}
                    onChange={onThresholdChange}
                    disabled={
                        initialConfig.selectedEndpoint !== Endpoint.AgeVerify &&
                        initialConfig.selectedEndpoint !== Endpoint.AgeAntispoofingVerify
                    }
                />
            )}
            {validRoleConfig(Sections.SecureMode, currentRole) && (
                <>
                    <SecureModeSection
                        secureModeList={secureModeList}
                        value={initialConfig.selectedSecureMode}
                        onChange={onSecureModeChange}
                    />
                </>
            )}
            {validRoleConfig(Sections.AgeDisplayMode, currentRole) && (
                <>
                    <AgeDisplayModeSection
                        ageDisplayModeList={ageDisplayModeList}
                        value={initialConfig.selectedAgeDisplayMode}
                        onChange={onAgeDisplayModeChange}
                        disabled={
                            initialConfig.selectedEndpoint !== Endpoint.Age &&
                            initialConfig.selectedEndpoint !== Endpoint.AgeAntispoofing
                        }
                    />
                </>
            )}
            {validRoleConfig(Sections.Language, currentRole) && (
                <>
                    <LanguageFieldSection
                        value={initialConfig.selectedLanguage}
                        onChange={onLanguageFieldChange}
                    />
                </>
            )}
            {validRoleConfig(Sections.ConfigWrapper, currentRole) && (
                <>
                    <ConfigWrapper onError={onError} property={'saveImagesScanTool'} />
                </>
            )}
            {Config.showConsent && (
                <ConsentConfig onChange={onConsentChange} consentGiven={initialConfig.consent} />
            )}
        </>
    );
};
