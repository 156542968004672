/* eslint-disable react/button-has-type */
import './button.scss';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as VARIATIONS from './buttonVariations';
import * as SIZES from './buttonSizes';
import ButtonIconSide from './button-icon-side';

function Button({
  children,
  type,
  variation,
  size,
  disabled,
  fullWidth,
  autoFocus,
  onClick,
  iconStart,
  iconEnd,
  'data-qa': dataQA,
}) {
  const buttonRef = useRef();
  const buttonStyle = classnames('yoti-button', {
    'yoti-button--secondary': variation === VARIATIONS.SECONDARY,
    'yoti-button--tertiary': variation === VARIATIONS.TERTIARY,
    'yoti-button--success': variation === VARIATIONS.SUCCESS,
    'yoti-button--danger': variation === VARIATIONS.DANGER,
    'yoti-button--small': size === SIZES.SMALL,
    'yoti-button--large': size === SIZES.LARGE,
    'yoti-button--full-width': fullWidth === true,
  });

  useEffect(() => {
    if (autoFocus) buttonRef.current.focus();
  }, [autoFocus, buttonRef]);

  return (
    <button
      ref={buttonRef}
      type={type}
      disabled={disabled}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      onClick={onClick}
      className={buttonStyle}
      data-qa={dataQA}
    >
      <ButtonIconSide type="start" icon={iconStart} />
      <span className="yoti-button__children">{children}</span>
      <ButtonIconSide type="end" icon={iconEnd} />
    </button>
  );
}

Button.defaultProps = {
  children: null,
  type: 'button',
  disabled: false,
  fullWidth: false,
  autoFocus: false,
  variation: VARIATIONS.PRIMARY,
  size: SIZES.MEDIUM,
  iconStart: undefined,
  iconEnd: undefined,
  onClick: () => {},
  'data-qa': 'button',
};

Button.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  variation: PropTypes.oneOf(Object.values(VARIATIONS)),
  size: PropTypes.oneOf(Object.values(SIZES)),
  iconStart: PropTypes.func,
  iconEnd: PropTypes.func,
  onClick: PropTypes.func,
  'data-qa': PropTypes.string,
};

export default Button;
