import React from 'react';
import { ConfigSection } from '../config/ConfigSection';
import { Steps } from './Steps';

/**
 * Component to print the user guide step list.
 *
 * @param children A different step number will be assigned to each child of the component.
 */
const ScanUserGuide: React.FC = ({ children }) => {
    const childrenArray = React.Children.map(children, (child) => child);
    return (
        <div>
            <ConfigSection title="How to scan:">
                <Steps align={'center'} steps={childrenArray} />
            </ConfigSection>
        </div>
    );
};

export default ScanUserGuide;
