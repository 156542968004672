import React from 'react';
import styles from './turnscreen.module.scss';
import RotateImg from '../../../assets/Rotate_Mobile.svg';

const portraitModeMessage = 'Please, use portrait mode for this app.';
const portraitModeAlt = 'Rotate your phone to portrait mode';

export const TurnScreen: React.FC = () => {
    return (
        <div className={styles.container}>
            <img src={RotateImg} className={styles.img} alt={portraitModeAlt} />
            <p>{portraitModeMessage}</p>
        </div>
    );
};
