import Axios, { AxiosRequestConfig } from 'axios';
import { invalidSessionError } from '../models/account';
import { LOGIN_PATH } from '../utils/constants/Routes';
import { AGE_ANTISPOOFING_CONFIG_KEY, SESSION_KEY, STATE_KEY } from '../utils/constants/storage';

export const REDIRECT_QUERY_STRING_PARAM = 'r';

// TODO: AITOOL-1708: Remove only the account data.
function removeCookies() {
    const res = document.cookie;
    const multiple = res.split(';');
    for (let i = 0; i < multiple.length; i++) {
        const key = multiple[i].split('=');
        document.cookie = `${key[0]} =; expires = Thu, 01 Jan 1970 00:00:00 UTC`;
    }
}

/**
 * Reset the consent value in the local storage to be 'false' when the user is
 * redirected to the login page because the session has expired.
 */
function resetConsent() {
    const scanConfigJSON = localStorage.getItem(AGE_ANTISPOOFING_CONFIG_KEY);
    const scanConfig = JSON.parse(scanConfigJSON || '{}');
    scanConfig.consent = false;
    const newScanConfigJSON = JSON.stringify(scanConfig);
    localStorage.setItem(AGE_ANTISPOOFING_CONFIG_KEY, newScanConfigJSON);
}

// removeLocalSessionData cleans the cache and local storage account data.
//
// TODO AITOOL-1708: Remove only the account data.
export function removeLocalSessionData() {
    // Remove all cookies.
    removeCookies();
    // Remove all local storage keys related to the account.
    localStorage.removeItem(SESSION_KEY);
    localStorage.removeItem(STATE_KEY);
    resetConsent();
}

export class ApiBase {
    protected url: string;
    public loginPath: string;

    constructor(url: string) {
        if (!url) {
            throw Error('URL cannot be empty');
        }
        if (!url.endsWith('/')) {
            url += '/';
        }
        this.url = url;
        this.loginPath = LOGIN_PATH;
    }

    protected async fetch<T>(url: string, init?: AxiosRequestConfig): Promise<T> {
        // Build url for current request. Use base url as root in case it
        // does not start with http or https.
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            if (url && url.startsWith('/')) {
                url = url.substr(1);
            }
            url = `${this.url}${url}`;
        }

        try {
            const res = await Axios(url, init);

            return res.data;
        } catch (e: any) {
            // 'e' is 'any' because ts(1196) lint rule.
            if (
                e.response?.status &&
                e.response?.data?.error_message &&
                e.response?.data?.error_code
            ) {
                if (e.response?.data?.error_code === invalidSessionError) {
                    removeLocalSessionData();
                    return Promise.reject();
                }
                throw new ApiError(
                    e.response.status,
                    e.response.data.error_message,
                    e.response.data.error_code,
                );
            } else {
                throw e;
            }
        }
    }
}

/**
 * Returns the error reason from the error message. It is the latest segment in
 * 'str'. The token that joins all segments is '::'.
 * @param str The error message string.
 */
function latestErrorMsg(str: string): string {
    const messages = str.split('::');
    if (messages.length === 0) {
        return '';
    }
    return messages[messages.length - 1].trim();
}

export class ApiError extends Error {
    status: number;
    code?: string;

    constructor(status: number, message: string, code?: string) {
        super(message);
        this.status = status;
        this.code = code;
    }

    toString(): string {
        return `Request failed (${this.status}): ${latestErrorMsg(this.message)}`;
    }
}
