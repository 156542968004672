import { GoogleState, Login, LoginType } from '../../models/account';
import Config from '../../utils/config';
import { STATE_KEY } from '../../utils/constants/storage';
import { getLocation } from '../../utils/redirect';
import { REDIRECT_QUERY_STRING_PARAM } from '../base';
import AccountService from './account';

const accountService = new AccountService(Config.apiUrl);

export const callback = (query: URLSearchParams): Login => {
    const code = query.get('code') || '';
    const state = query.get('state') || '';
    const storedState = localStorage.getItem(STATE_KEY);

    // Throw an error if the login state token because it is needed to
    // authenticate the users.
    if (!storedState) {
        throw Error('login state key is empty');
    }

    const stateJson = atob(state);
    const stateEntity = JSON.parse(stateJson) as GoogleState;
    const redirect = stateEntity.redirect ? getLocation(stateEntity.redirect) : undefined;

    return {
        type: LoginType.Google,
        data: { code, state, storedState },
        redirect: redirect,
    };
};

export const begin = (query: URLSearchParams): Promise<void> => {
    const r = query.get(REDIRECT_QUERY_STRING_PARAM);

    return accountService.login(LoginType.Google, r || undefined).then((res) => {
        localStorage.setItem(STATE_KEY, res.state);
        window.location.replace(res.url);
    });
};
