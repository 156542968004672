import { useState, useEffect, useCallback } from 'react';
import { useGetLocalStorage } from './getLocalStorage';

export function useLocalStorage<T>(
    key: string,
    defaultValue: T,
    typeGuard?: (value: any) => value is T,
): [T, (value: T) => void] {
    const rawValue = useGetLocalStorage(key, typeGuard);

    const checkValue = useCallback(() => {
        if (rawValue !== null) {
            return rawValue;
        } else {
            return defaultValue;
        }
    }, [rawValue, defaultValue]);

    const [value, setValue] = useState<T>(checkValue());

    useEffect(() => {
        setValue(checkValue());
    }, [checkValue]);

    const updateValueOnLocalStorage = useCallback(
        (newValue: T) => {
            setValue(newValue);
            localStorage.setItem(key, JSON.stringify(newValue));
        },
        [key],
    );

    return [value, updateValueOnLocalStorage];
}
