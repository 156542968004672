const isLocalStorageAvailable = () => {
    try {
        const localStorageVar = 'checking_local_storage';
        localStorage.setItem(localStorageVar, "it's a valid configuration");
        localStorage.removeItem(localStorageVar);
        return true;
    } catch (e) {
        return false;
    }
};

export default isLocalStorageAvailable;
