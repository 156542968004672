import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useCallback, useEffect, useState } from 'react';

export enum Status {
    Loading = 'LOADING',
    Done = 'DONE',
    Abort = 'ABORT',
    Error = 'ERROR',
}

export interface RequestInfo<T> {
    /** The latest request result */
    data?: T;
    /** The latest request error */
    error?: AxiosError;
    /** Indicates if the hook is performing the request */
    status?: Status;
    /** Method that can abort the existing request if it is loading */
    abort: () => void;
}

export interface RequestConfig extends Omit<AxiosRequestConfig, 'baseURL'> {
    /** Indicates if the hook will trigger the request the first time that it
     * runs (automatically). */
    auto?: boolean;
}

export interface HookResult<T> extends RequestInfo<T> {
    /**
     * Perform the api call over the service with the 'url' provided in the
     * hook argument.
     * @param options The options to perform the request.
     * @returns The 'cancel' method to abort the HTTP request.
     */
    call: (options?: RequestConfig) => void;
}

const initialAbort = () => {};

/**
 * Hook to perform an HTTP request over the endpoint ('url') provided. It will
 * abort the request if it is destroyed while the status is 'LOADING'.
 *
 * @param url The endpoint where the hook will perform the request.
 * @param options The options to be applied. The hook will perform the request
 * automatically if this field is provided. If content type is not provided in
 * the headers, the hook will use 'application/json' as default value.
 * @returns The request status (data, error, loading) and the method to perform
 * the request (call) and abort it (abort).
 */
export function useApi<T>(url: string, options?: RequestConfig): HookResult<T> {
    const [state, setState] = useState<RequestInfo<T>>({ abort: initialAbort });
    const { status, abort } = state;

    /**
     * Request the service endpoint provided in hook 'url' param and returns the
     * cancel method to abort the request.
     */
    const call = useCallback(
        (options?: RequestConfig) => {
            const cancelTokenSrc = Axios.CancelToken.source();
            options = { ...options, cancelToken: cancelTokenSrc.token };

            const endpoint = options && options.url ? options.url : url;

            setState((previous) => ({
                ...previous,
                data: undefined,
                error: undefined,
                status: Status.Loading,
                abort: cancelTokenSrc.cancel,
            }));
            const headers = { 'Content-Type': 'application/json', ...options.headers };
            Axios.request({ ...options, url: endpoint, headers })
                .then((res) => {
                    setState((previous) => ({
                        ...previous,
                        data: res.data,
                        status: Status.Done,
                        abort: initialAbort,
                    }));
                })
                .catch((e: AxiosError) => {
                    const isCancelError = Axios.isCancel(e);

                    setState((previous) => ({
                        ...previous,
                        error: isCancelError ? undefined : e,
                        status: isCancelError ? Status.Abort : Status.Error,
                        abort: initialAbort,
                    }));
                });

            return cancelTokenSrc.cancel;
        },
        [url],
    );

    // Abort the request if it is loading and this hook is destroyed.
    useEffect(() => {
        const currentStatus = status;
        const currentAbort = abort;
        return () => {
            if (currentStatus === Status.Loading) {
                currentAbort();
            }
        };
    }, [abort, status]);

    // Handle auto call.
    const optionsDep = JSON.stringify(options);
    useEffect(() => {
        // Perform the request if the 'options' were provided to the hook.
        const options = optionsDep ? (JSON.parse(optionsDep) as RequestConfig) : undefined;
        if (!options || !options.auto) {
            return;
        }

        // Request the server and abort in case the effect finishes.
        const abort = call(options);
        setState((previous) => ({ ...previous, abort }));
        return () => abort();
    }, [optionsDep, call]);

    // 'callFn' is a wrapper for 'call' to not provide the 'abort' function
    // outside the hook as 'call' returns it. The caller can access to 'abort'
    // in the hook result.
    const callFn = useCallback(
        (options?: RequestConfig) => {
            call(options);
        },
        [call],
    );

    return {
        ...state,
        call: callFn,
    };
}
