import React from 'react';
import Card from '../card';
import Icon from '@yoti/react-components/icon';
import * as iconsList from '@yoti/react-components/icons-list';
import styles from './Home.module.scss';
import { useHistory } from 'react-router-dom';
import { HeaderTab } from '../commons';
import { AGE_ANTISPOOFING_PATH } from '../../utils/constants/Routes';

interface PanelConfiguration {
    title: string;
    iconComponent: React.ReactNode;
    path: string;
}

export const panelsConfiguration: PanelConfiguration[] = [
    {
        title: 'Age & Antispoofing',
        iconComponent: <Icon component={iconsList[`camera`]} className={styles['home__icon']} />,
        path: AGE_ANTISPOOFING_PATH,
    },
    {
        title: 'Face Matching',
        iconComponent: (
            <div className={styles['home__face-matching']}>
                <Icon component={iconsList[`face`]} className={styles['home__icon']} />
                <Icon component={iconsList[`face`]} className={styles['home__icon']} />
            </div>
        ),
        path: 'face-matching',
    },
];

const Home: React.FC = () => {
    const history = useHistory();

    return (
        <div>
            <HeaderTab className={styles['home__tab']} tabs={['Select tool']}></HeaderTab>
            <div className={styles['home__cards-container']}>
                {panelsConfiguration.map((p, i) => {
                    return (
                        <Card key={i} title={p.title} onClick={() => history.push(p.path)}>
                            {p.iconComponent}
                        </Card>
                    );
                })}
            </div>
        </div>
    );
};

export default Home;
