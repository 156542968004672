import './select-field.scss';
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { createUniqueId } from '../services/utils';
import * as SIZES from './select-field-sizes';
import { chevronDown as ChevronDown } from '../icons-list';
import { ReactComponent as DismissThick } from '../assets/dismiss-thick.svg';

const prefixIdentifier = 'yoti-select-field';

function SelectField({
  id,
  selectedValue: externalSelectedValue,
  options,
  size,
  name,
  label,
  disabled,
  error,
  hint,
  tooltipFloating,
  showOptionsLabel,
  autoComplete,
  autoFocus,
  onChange,
  onBlur,
  onFocus,
  tooltipFallbackLegacy,
  'data-qa': dataQa,
}) {
  const [active, setActive] = useState(false);
  const [internalSelectedValue, setInternalSelectedValue] = useState();
  const [defaultId] = useState(`${prefixIdentifier}-${createUniqueId()}`);
  const idValue = id || defaultId;
  const selectedValue = externalSelectedValue || internalSelectedValue;
  const isInvalid = error && !disabled;
  const isLabelFullHeight = !selectedValue && !active && !error;
  const showEmptyOption = !selectedValue;

  const handleFocus = (event) => {
    onFocus(event);
    setActive(true);
  };

  const handleBlur = (event) => {
    onBlur(event);
    setActive(false);
  };

  const handleChange = (event) => {
    setInternalSelectedValue(event.target.value);
    onChange(event);
  };

  const rootClasses = classNames({
    'yoti-select-field': true,
    'yoti-select-field--has-value': !!selectedValue,
    'yoti-select-field--invalid': isInvalid,
    'yoti-select-field--disabled': disabled,
    'yoti-select-field--active': active,
    'yoti-select-field--small': size === SIZES.SMALL,
  });

  const labelClasses = classNames({
    'yoti-select-field__label': true,
    'yoti-select-field__label--full-height': isLabelFullHeight,
  });

  const inputClasses = classNames({
    'yoti-select-field__input': true,
  });

  const tooltipClasses = classNames({
    'yoti-select-field__tooltip': true,
    'yoti-select-field__tooltip--floating': tooltipFloating,
    'yoti-select-field__tooltip--error': isInvalid,
    'yoti-select-field__tooltip--fallback-legacy': tooltipFallbackLegacy,
  });

  return (
    <div className={rootClasses}>
      <div className="yoti-select-field__input-group">
        {isInvalid && <DismissThick className="yoti-select-field__icon" />}
        <label htmlFor={idValue} className={labelClasses}>
          {label}
        </label>

        <select
          id={idValue}
          disabled={disabled}
          value={selectedValue}
          name={name}
          autoComplete={autoComplete}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          className={inputClasses}
          data-qa={dataQa}
        >
          {showEmptyOption && (
            <option value="" className="yoti-select-field__empty-option">
              {' '}
            </option>
          )}
          {showOptionsLabel && <option disabled label={label} />}
          {options.map(({ value, label: optionLabel }) => (
            <option key={value} value={value}>
              {optionLabel}
            </option>
          ))}
        </select>

        {!disabled && <ChevronDown className="yoti-select-field__icon-chevron" />}
      </div>

      <div className={tooltipClasses} data-qa={`${dataQa}-tooltip`}>
        {error || hint}
      </div>
    </div>
  );
}

SelectField.defaultProps = {
  selectedValue: undefined,
  options: [],
  id: undefined,
  size: SIZES.LARGE,
  name: undefined,
  label: undefined,
  disabled: undefined,
  showOptionsLabel: false,
  error: undefined,
  hint: undefined,
  tooltipFloating: false,
  autoComplete: undefined,
  autoFocus: false,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  tooltipFallbackLegacy: false,
  'data-qa': prefixIdentifier,
};

SelectField.propTypes = {
  selectedValue: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  id: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  showOptionsLabel: PropTypes.bool,
  error: PropTypes.string,
  hint: PropTypes.string,
  tooltipFloating: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  tooltipFallbackLegacy: PropTypes.bool,
  'data-qa': PropTypes.string,
};

export default SelectField;
