import React from 'react';
import styles from './resultFaceMatching.module.scss';
import { HeaderTab } from '../../commons';
import Button from '@yoti/react-components/button';
import { PredictionResponse, FaceMatchingResponseData } from '../../../models/prediction';
import TimeoutCircularLoading, {
    DEFAULT_LOADING_MESSAGE,
    TIMEOUT_MESSAGE,
    TIMEOUT_DELAY,
} from '../../timeout-loading';
import { useMediaQuery, MediaQuery } from '../../../hooks';
import { ConfigProps } from '../../config/ConfigPanel';
import ResultCard, { ResultCardVariant } from '../card';
import FaceMatchingResponse from './FaceMatchingResponse';
import { AgeAntispoofingResponse } from '../age-antispoofing';

interface FaceMatchingImage {
    image: string;
    result?: PredictionResponse;
}

interface Props {
    firstImage: FaceMatchingImage;
    secondImage: FaceMatchingImage;
    result?: FaceMatchingResponseData;
    onBackButtonClick: () => void;
    configuration: ConfigProps;
}

const ResultFaceMatching: React.FC<Props> = (props) => {
    const { firstImage, secondImage, result, onBackButtonClick, configuration } = props;

    const isLaptop = useMediaQuery(MediaQuery.lg);

    return (
        <div className={styles['result']}>
            <HeaderTab className={styles['result__header']} tabs={['Result']} />
            <div className={styles['result__panel']}>
                <div className={styles['result__panel__card-container']}>
                    <ResultCard
                        variant={isLaptop ? ResultCardVariant.Bottom : ResultCardVariant.Right}
                        className={styles['result__panel__card-container__card']}
                        content={
                            <img
                                className={styles['result__panel__card-container__card__image']}
                                src={firstImage.image}
                                alt="face"
                                data-qa="first-image"
                            />
                        }
                        secondaryContent={
                            firstImage.result ? (
                                <AgeAntispoofingResponse
                                    responseData={firstImage.result}
                                    configuration={configuration}
                                />
                            ) : undefined
                        }
                    />
                    <ResultCard
                        variant={isLaptop ? ResultCardVariant.Bottom : ResultCardVariant.Right}
                        className={styles['result__panel__card-container__card']}
                        content={
                            <img
                                className={styles['result__panel__card-container__card__image']}
                                src={secondImage.image}
                                alt="face"
                                data-qa="second-image"
                            />
                        }
                        secondaryContent={
                            secondImage.result ? (
                                <AgeAntispoofingResponse
                                    responseData={secondImage.result}
                                    configuration={configuration}
                                />
                            ) : undefined
                        }
                    />
                </div>

                <div className={styles['result__panel__response']}>
                    {result ? (
                        <FaceMatchingResponse responseData={result} configuration={configuration} />
                    ) : (
                        <TimeoutCircularLoading
                            size={isLaptop ? '80px' : '40px'}
                            message={DEFAULT_LOADING_MESSAGE}
                            timeoutMessage={TIMEOUT_MESSAGE}
                            timeout={TIMEOUT_DELAY}
                        />
                    )}
                </div>
                <div className={styles['result__panel__back-button']}>
                    <Button onClick={onBackButtonClick}>Back</Button>
                </div>
            </div>
        </div>
    );
};

export default ResultFaceMatching;
