import React, { useEffect } from 'react';
import { Route as ReactRoute, RouteProps as ReactRouteProps, useHistory } from 'react-router-dom';
import { REDIRECT_QUERY_STRING_PARAM } from '../../api/base';
import { useCheckSession } from '../../hooks';
import { LOGIN_PATH } from '../../utils/constants/Routes';
import { generateInfoToken } from '../../utils/redirect';
import CircularLoading from './../loading';
import styles from './route.module.scss';

interface RouteProps extends ReactRouteProps {
    anonymous?: boolean;
}

const Route: React.FC<RouteProps> = (props) => {
    const { anonymous, path } = props;
    const { account, accountChecked } = useCheckSession(anonymous);
    const history = useHistory();

    // Effect to check if the user is authenticated or not in case the
    // application is rendering a page that needs it.
    useEffect(() => {
        const isValidSession = account?.token && account?.verified && !account?.banned;

        // The authentication was already checked.
        if (accountChecked && isValidSession) {
            return;
        }

        // The page does not need any authentication.
        if (anonymous) {
            return;
        }

        // The page needs authentication but is invalid.
        if (!anonymous && accountChecked && !isValidSession) {
            const redirectPath = generateInfoToken(history.location);
            history.replace(`${LOGIN_PATH}?${REDIRECT_QUERY_STRING_PARAM}=${redirectPath}`);
        }
    }, [anonymous, accountChecked, history, path, account]);

    if (!anonymous && (!accountChecked || !account)) {
        return (
            <div className={styles['route__loading-container']}>
                <CircularLoading />
            </div>
        );
    }

    return <ReactRoute {...props}>{props.children}</ReactRoute>;
};

export default Route;
