import React from 'react';
import { Endpoint, getEndpointName } from '../../../models/prediction';
import { ConfigSection } from '../ConfigSection';
import { ConfigProps } from '../ConfigPanel';
import RadioButtonList, { RadioButtonEntry } from '../radioButtonsList';

export interface ConfigEndpointProps {
    endpointsList: Endpoint[];
    config: ConfigProps;
    onConfigChange: (config: ConfigProps) => void;
}

const EndpointsSection: React.FC<ConfigEndpointProps> = ({
    endpointsList,
    config: initialConfig,
    onConfigChange,
}) => {
    const { selectedEndpoint, endpoints } = initialConfig;
    const endpointRadioButtonList: RadioButtonEntry<Endpoint>[] = endpointsList.map((endpoint) => {
        const endpointConfig = endpoints.find((e) => e.key === endpoint);
        return {
            value: endpoint,
            label: getEndpointName(endpoint),
            subConfig: endpointConfig?.cfg?.renderConfig(endpoint, initialConfig, onConfigChange),
        };
    });
    const onChange = (element: Endpoint) => {
        onConfigChange({
            ...initialConfig,
            selectedEndpoint: element,
        });
    };

    return (
        <ConfigSection title="Endpoints">
            <RadioButtonList
                list={endpointRadioButtonList}
                selectedValue={selectedEndpoint}
                onChange={onChange}
            />
        </ConfigSection>
    );
};

export default EndpointsSection;
