import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './withError.scss';

export function withError(WrappedComponent, fullWidthDeprecated) {
  const HOC = ({ errors, fullWidth, ...props }) => (
    <span
      className={cn('yoti-with-error', {
        'yoti-with-error--full-width': fullWidth || fullWidthDeprecated,
      })}
      data-qa="yoti-with-error"
    >
      <span className="yoti-with-error__component">
        <WrappedComponent fullWidth={fullWidth || fullWidthDeprecated} {...props} />
      </span>
      <span className="yoti-with-error__message" data-qa="error-message">
        {errors && errors[0]}
      </span>
    </span>
  );

  HOC.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string),
    fullWidth: PropTypes.bool,
  };

  HOC.defaultProps = {
    errors: [],
    fullWidth: false,
  };

  return HOC;
}
