import React from 'react';
import { ConfigSubpanelProps } from './ConfigPanel';
import { ConfigSection } from './ConfigSection';
import RadioButtonList, { RadioButtonEntry } from './radioButtonsList';
import { ExpectedBrightness, ExpectedLocation, ExpectedResult } from '../../models/prediction';

// Field lists
const expectedResultFieldList = Object.values(ExpectedResult);
const expectedLocationFieldList = Object.values(ExpectedLocation);
const expectedBrightnessFieldList = Object.values(ExpectedBrightness);

export const DataCollectorConfigPanel: React.FC<ConfigSubpanelProps> = ({
    config: initialConfig,
    onConfigChange,
}) => {
    // Change config methods
    const onExpectedResultChange = (element?: ExpectedResult) =>
        onConfigChange({
            ...initialConfig,
            selectedExpectedResult:
                element === initialConfig.selectedExpectedResult ? undefined : element,
        });
    const onExpectedLocationChange = (element?: ExpectedLocation) =>
        onConfigChange({
            ...initialConfig,
            selectedExpectedLocation:
                element === initialConfig.selectedExpectedLocation ? undefined : element,
        });
    const onExpectedBrightnessChange = (element?: ExpectedBrightness) =>
        onConfigChange({
            ...initialConfig,
            selectedExpectedBrightness:
                element === initialConfig.selectedExpectedBrightness ? undefined : element,
        });

    // Radio buttons lists
    const expectedResultRadioButtonList: RadioButtonEntry<ExpectedResult>[] = expectedResultFieldList.map(
        (field) => {
            let labelSplitted = (field.charAt(0).toUpperCase() + field.slice(1)).split('_');
            let label = `${labelSplitted[0]} ${labelSplitted[1] ? labelSplitted[1] : ''}`;
            return { value: field, label: label };
        },
    );
    const expectedLocationRadioButtonList: RadioButtonEntry<ExpectedLocation>[] = expectedLocationFieldList.map(
        (field) => {
            return { value: field, label: field.charAt(0).toUpperCase() + field.slice(1) };
        },
    );
    const expectedBrightnessRadioButtonList: RadioButtonEntry<ExpectedBrightness>[] = expectedBrightnessFieldList.map(
        (field) => {
            return { value: field, label: field.charAt(0).toUpperCase() + field.slice(1) };
        },
    );

    return (
        <>
            <ConfigSection title="Expected result">
                <RadioButtonList
                    list={expectedResultRadioButtonList}
                    selectedValue={initialConfig.selectedExpectedResult}
                    onChange={onExpectedResultChange}
                />
            </ConfigSection>
            <ConfigSection title="Expected location">
                <RadioButtonList
                    list={expectedLocationRadioButtonList}
                    selectedValue={initialConfig.selectedExpectedLocation}
                    onChange={onExpectedLocationChange}
                />
            </ConfigSection>
            <ConfigSection title="Expected brightness">
                <RadioButtonList
                    list={expectedBrightnessRadioButtonList}
                    selectedValue={initialConfig.selectedExpectedBrightness}
                    onChange={onExpectedBrightnessChange}
                />
            </ConfigSection>
        </>
    );
};
