import { CheckboxBase } from '@yoti/react-components/checkbox';
import '@yoti/react-components/select-field/select-field.css';
import React, { useState } from 'react';
import { Dialog } from '../commons';
import { ConfigSection } from '../config/ConfigSection';

interface Props {
    consentGiven: boolean;
    onChange: (consentGiven: boolean) => void;
}

const ConsentSection: React.FC<Props> = ({ consentGiven, onChange }) => {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    return (
        <ConfigSection title="Consent">
            <CheckboxBase
                label="Consent"
                onChange={() => {
                    // Show the confirmation dialog if the user un-select the
                    // checkbox. Keep in mind that 'value' contains the
                    // selection before the user clicks in the checkbox and this
                    // event is triggered so 'value === true' means that the
                    // user un-selected the checkbox.
                    if (consentGiven) {
                        setShowConfirmDialog(true);
                    } else {
                        onChange(!consentGiven);
                    }
                }}
                checked={consentGiven}
            />
            {showConfirmDialog && (
                <Dialog
                    title={'Consent'}
                    action={'Accept'}
                    onConfirm={() => onChange(!consentGiven)}
                    onClose={() => setShowConfirmDialog(false)}
                    message={'withdraw your consent'}
                />
            )}
        </ConfigSection>
    );
};

export default ConsentSection;
