import React, { useState, useEffect } from 'react';
import Header from './Header';
import styles from './layOut.module.scss';
import Footer from './Footer';
import { useHistory } from 'react-router-dom';
import { ROOT_PATH } from '../../utils/constants/Routes';

const LayOut: React.FC = (props) => {
    const history = useHistory();
    const [hasLinkToMainPage, setHasLinkToMainPage] = useState<boolean>(false);

    useEffect(() => {
        if (hasLinkToMainPage && history.location.pathname === ROOT_PATH) {
            setHasLinkToMainPage(false);
        } else {
            setHasLinkToMainPage(history.location.pathname !== ROOT_PATH);
        }
        return history.listen((location) => {
            setHasLinkToMainPage(location.pathname !== ROOT_PATH);
        });
    }, [history, hasLinkToMainPage]);

    return (
        <>
            <div>
                <Header hasLinkToMainPage={hasLinkToMainPage} />
                <div className={styles['layout']}>{props.children}</div>
            </div>
            <Footer />
        </>
    );
};

export default LayOut;
