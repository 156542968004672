import React, { Fragment } from 'react';
import { FirstLanding } from '../../../hooks/firstLanding';
import { RoleCode, Session } from '../../../models/account';
import { AGE_ANTISPOOFING_CONFIG_KEY } from '../../../utils/constants/storage';
import { SubtextColor } from '../../commons';
import GuidePanel from '../../guide';

interface Props {
    account?: Session;
    firstLandingConfig: FirstLanding;
    set: (previous: any) => void;
}

const getGuidePanelChildren = (account?: Session) => {
    const result: JSX.Element[] = [];
    if (account?.role === RoleCode.DataCollector) {
        result.push(
            <Fragment key={'collector'}>
                Select the desired expected result, location and brightness at the collector tab.
            </Fragment>,
        );
    }
    result.push(
        <Fragment key={'config'}>
            Select the desired endpoint at the settings tab.
            <SubtextColor>(Default is Antispoofing)</SubtextColor>
        </Fragment>,
    );
    result.push(<Fragment key={'scan'}>Go to the Scan tab and follow the instructions.</Fragment>);

    return result;
};

const Guide: React.FC<Props> = ({ account, firstLandingConfig, set }) => {
    return (
        <GuidePanel
            section={AGE_ANTISPOOFING_CONFIG_KEY}
            firstLandingConfig={firstLandingConfig}
            set={set}
        >
            {getGuidePanelChildren(account)}
        </GuidePanel>
    );
};

export default Guide;
