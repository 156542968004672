import classNames from 'classnames';
import React from 'react';
import styles from './steps.module.scss';

type ReactChildrenList =
    | (
          | string
          | number
          | {}
          | React.ReactElement<any, string | React.JSXElementConstructor<any>>
          | React.ReactNodeArray
          | React.ReactPortal
      )[]
    | null
    | undefined;

interface StepsProps {
    steps: ReactChildrenList;
    align: 'start' | 'center';
}

/**
 * This component generates an enumerated list for a set of steps.
 *
 * @param steps An array from wich each element will be represented as a unique step.
 * @param align Specify whether the step text is aligned vertically at the center or at the start of the space.
 */
export const Steps: React.FC<StepsProps> = (props) => {
    const { steps, align } = props;

    return (
        <>
            {steps &&
                steps.map((step, i) => {
                    return (
                        <div
                            key={`step-${i}`}
                            className={styles['step-container']}
                            style={{
                                alignItems: `${align === 'start' ? 'flex-start' : 'flex-start'}`,
                            }}
                        >
                            <div className={styles['step-container__number']}>
                                <div>{i + 1}</div>
                            </div>
                            <div className={styles['step-container__line']} />
                            <div
                                className={classNames(
                                    align === 'center'
                                        ? styles['step-container__centered']
                                        : undefined,
                                )}
                            >
                                {step}
                            </div>
                        </div>
                    );
                })}
        </>
    );
};
