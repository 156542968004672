import React from 'react';
import { AgeEstimationModelField } from '../../../models/prediction';
import { ConfigSection } from '../ConfigSection';
import RadioButtonList, { RadioButtonEntry } from '../radioButtonsList';

interface Props {
    list: AgeEstimationModelField[];
    value?: AgeEstimationModelField;
    onChange: (value?: AgeEstimationModelField) => void;
    disabled: boolean;
}

const AgeEstimationModelSection: React.FC<Props> = ({
    list,
    value: selectedAgeEstimationModelField,
    disabled,
    onChange,
}) => {
    const radioElements: RadioButtonEntry<AgeEstimationModelField>[] = list.map((field) => {
        return { value: field, label: field };
    });
    const onValueChange = (element: AgeEstimationModelField) =>
        onChange(element === selectedAgeEstimationModelField ? undefined : element);

    return (
        <ConfigSection title="Age Estimation Model">
            <RadioButtonList
                list={radioElements}
                selectedValue={selectedAgeEstimationModelField}
                onChange={onValueChange}
                disabled={disabled}
            />
        </ConfigSection>
    );
};

export default AgeEstimationModelSection;
