import React, { PropsWithChildren } from 'react';
import { isMobile } from 'react-device-detect';
import { useScreenOrientation } from '../../../hooks/useScreenOrientation/useScreenOrientation';

interface Props {
    alternativeComponent: React.ReactNode;
}

/** OrientationHandler will display `alternativeComponent` component if
 * the device using the app is a mobile and its orientation is landscape;
 * otherwise, it will display its children.
 */
export const OrientationHandler: React.FC<PropsWithChildren<Props>> = ({
    children,
    alternativeComponent,
}) => {
    const orientation = useScreenOrientation();
    const mustTurnScreen = isMobile && orientation === 'landscape';

    return mustTurnScreen ? <>{alternativeComponent}</> : <>{children}</>;
};
