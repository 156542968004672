import React from 'react';
import styles from './Card.module.scss';

interface Props {
    title: string;

    onClick: () => void;
}

const Card: React.FC<Props> = (props) => {
    const { title, children, onClick } = props;

    return (
        <div
            role="button"
            tabIndex={0}
            className={styles['card']}
            onClick={onClick}
            onKeyDown={onClick}
        >
            <div className={styles['card__header']}>
                <span className={styles['card__title']} data-qa="title">
                    {title}
                </span>
            </div>
            <div className={styles['card__content']}>{children}</div>
        </div>
    );
};

export default Card;
