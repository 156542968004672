import { useGlobalContext } from '../../globalContext';
import { useHistory } from 'react-router-dom';
import useNotify from '../../hooks/useNotify';
import { ALERT_TYPES } from '@yoti/react-components/alert';
import Config from '../../utils/config';
import AccountService from '../../api/account/account';
import { useEffect } from 'react';
import { AxiosError } from 'axios';
import { LOGIN_PATH } from '../../utils/constants/Routes';

const accountService = new AccountService(Config.apiUrl);

export const useLogOut = (error: AxiosError<any> | undefined, onError: (error: Error) => void) => {
    const { set } = useGlobalContext();
    const history = useHistory();
    const notify = useNotify();

    useEffect(() => {
        if (error) {
            if (error.response?.data.error_code === 'INVALID_SESSION_ERROR') {
                notify({
                    message: 'Session expired',
                    type: ALERT_TYPES.DANGER,
                    icon: true,
                });
                accountService.logout();
                set((prev) => ({ ...prev, account: undefined, accountChecked: false }));
                history.replace(LOGIN_PATH);
            } else {
                onError(error);
            }
        }
    }, [error, onError, set, history, notify]);
};
