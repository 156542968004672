import { Endpoint } from '../../models/prediction';
import config from '../../utils/config';
import { ConfigProps } from '../config/ConfigPanel';

export const DEFAULT_CONFIG: ConfigProps = {
    selectedEndpoint: config.defaultConfig.endpoint,
    selectedSecureMode: config.defaultConfig.mode,
    endpoints: [
        { key: Endpoint.Antispoofing },
        { key: Endpoint.Age },
        { key: Endpoint.AgeAntispoofing },
        { key: Endpoint.AgeAntispoofingVerify },
        { key: Endpoint.AgeVerify },
        { key: Endpoint.SelfCheckoutAntiSpoofing },
    ],
    selectedAgeDisplayMode: config.defaultConfig.ageDisplayMode,
    selectedLanguage: config.defaultConfig.language,
    consent: false,
    selectedMetadataField: config.defaultConfig.metadata,
    selectedLevelOfAssuranceField: config.defaultConfig.levelOfAssurance,
    selectedAgeEstimationModelField: config.defaultConfig.ageEstimationModel,
    selectedOperatorField: config.defaultConfig.operator,
    selectedThresholdField: config.defaultConfig.threshold,
};
