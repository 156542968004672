import React, { useCallback, useEffect, useRef } from 'react';
import { NodeEnv } from '../../../utils/types';
import style from './button.module.scss';

interface Props {
    className?: string;
    clientSdkId: string;
    scenarioId: string;
    text: string;
    env: NodeEnv;
    onDebugButtonClick?: () => void;
    onTokenReceived: (token: string) => void;
}

const Button: React.FC<Props> = ({
    className,
    clientSdkId,
    env,
    scenarioId,
    text,
    onDebugButtonClick,
    onTokenReceived,
}) => {
    const buttonRef = useRef<HTMLDivElement>(null);

    const handleDebugButtonClick = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            onDebugButtonClick && onDebugButtonClick();
        },
        [onDebugButtonClick],
    );

    useEffect(() => {
        if (!buttonRef || !buttonRef.current) return;

        const yoti = (window as any).Yoti.Share.init({
            elements: [
                {
                    clientSdkId: clientSdkId,
                    scenarioId: scenarioId,
                    domId: buttonRef.current.id,
                    button: {
                        label: text,
                        width: 'full',
                    },
                    shareComplete: {
                        tokenHandler: (token: string) => {
                            onTokenReceived(token);
                        },
                    },
                },
            ],
        });

        return yoti.destroy;
    }, [clientSdkId, scenarioId, text, buttonRef, onTokenReceived]);

    return (
        <div className={style['button-container']}>
            {env !== 'production' && (
                <button
                    className={style['button-container__debug-button']}
                    onClick={handleDebugButtonClick}
                />
            )}
            <div
                className={`${className} ${style['button-container__button']}`}
                ref={buttonRef}
                id="yoti-button"
            />
        </div>
    );
};

export default Button;
