import './tab-header.scss';
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

export function TabHeader({
  children,
  title,
  isSelected,
  onClick,
  'aria-controls': ariaControls,
  'data-qa': dataQa,
}) {
  const rootClasses = classnames('yoti-tabs-header', { 'yoti-tabs-header--active': isSelected });

  return (
    <button
      data-qa={dataQa}
      type="button"
      className={rootClasses}
      onClick={onClick}
      role="tab"
      aria-selected={isSelected}
      aria-controls={ariaControls}
    >
      {title || children}
    </button>
  );
}

TabHeader.defaultProps = {
  children: '',
  title: '',
  isSelected: false,
  onClick: () => {},
  'aria-controls': null,
  'data-qa': '',
};

TabHeader.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  'aria-controls': PropTypes.string,
  'data-qa': PropTypes.string,
};
