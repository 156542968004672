import * as faceapi from 'face-api.js';

import React, { useEffect, useState } from 'react';

import GlobalContext from '../../globalContext';
import CircularLoading from '../loading';

const modelURL = '/static/models';

interface Props {
    onError: (error: Error) => void;
}

const TinyFace: React.FC<Props> = (props) => {
    const [tinyFaceError, setTinyFaceError] = useState<Error>();
    const { isTinyFaceReady, setIsTinyFaceReady } = React.useContext(GlobalContext);
    const { onError } = props;

    useEffect(() => {
        if (!isTinyFaceReady || tinyFaceError) {
            faceapi.nets.tinyFaceDetector
                .load(modelURL)
                .then(() => {
                    setIsTinyFaceReady(true);
                })
                .catch((error: Error) => {
                    setTinyFaceError(error);
                    onError(error);
                });
        }
    }, [isTinyFaceReady, setIsTinyFaceReady, tinyFaceError, setTinyFaceError, onError]);

    return <>{!tinyFaceError && isTinyFaceReady ? props.children : <CircularLoading />}</>;
};

export default TinyFace;
