import { Bbox } from '../models/faceCaptureModule';
import * as faceapi from 'face-api.js';

/**
 * Returns the bounding boxes for all faces in image provided.
 *
 * @param b64img Image the method will use to look for faces in base64.
 * @param threshold The minimum confidence threshold.
 */
export const getFaces = async (b64img: string, threshold: number): Promise<Bbox[]> => {
    const imgElement: HTMLImageElement = document.createElement('img');
    imgElement.src = b64img;
    const faces = await checkFace(imgElement, threshold);
    return faces.map((f) => {
        return { x: f.box.x, y: f.box.y, width: f.box.width, height: f.box.height };
    });
};

/**
 * Returns all the faces detected in image provided.
 *
 * @param b64img Image the method will use to look for faces in base64.
 * @param threshold The minimum confidence threshold.
 */
async function checkFace(
    b64img: HTMLImageElement,
    threshold: number,
): Promise<faceapi.FaceDetection[]> {
    const tinyFaceDetectorOptions = new faceapi.TinyFaceDetectorOptions({
        inputSize: 320,
        scoreThreshold: threshold,
    });
    const result: faceapi.FaceDetection[] = await faceapi.detectAllFaces(
        b64img,
        tinyFaceDetectorOptions,
    );
    return result;
}
