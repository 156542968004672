import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon';
import './button-icon-side.scss';

const classes = {
  start: 'yoti-button-icon-side--start',
  end: 'yoti-button-icon-side--end',
};

const dataQa = {
  start: 'button-icon-side-start',
  end: 'button-icon-side-end',
};

function IconSide({ type, icon }) {
  return (
    <span className={`yoti-button-icon-side ${classes[type]}`} data-qa={dataQa[type]}>
      {icon && <Icon component={icon} />}
    </span>
  );
}

IconSide.defaultProps = {
  icon: undefined,
};

IconSide.propTypes = {
  type: PropTypes.string.isRequired,
  icon: PropTypes.func,
};

export default IconSide;
