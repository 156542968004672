import React from 'react';
import { MediaQuery, useMediaQuery } from '../../hooks';
import styles from './faceMatchingTwoPanels.module.scss';
import classNames from 'classnames';
import YotiCircle from './YotiCircle';

interface Props {
    children: {
        Left: React.ReactNode;
        Right: React.ReactNode;
    };
    hideCircle?: boolean;
}

//TODO: AITOOL-1993: This is a temporal component, until we will improve the existing one(TwoSidesPanel).
const FaceMatchingTwoPanels: React.FC<Props> = ({ hideCircle, children }) => {
    const { Left, Right } = children;
    const isLaptop = useMediaQuery(MediaQuery.lg);

    return (
        <div className={styles['panel']}>
            {!isLaptop && <div className={styles['panel__header']} data-qa="header"></div>}

            <div className={styles['panel__column']} data-qa="primary-panel">
                {Left}
            </div>

            {!isLaptop && (
                <div className={styles['panel__mobile-right-container']} data-qa="secondary-panel">
                    {Right}
                </div>
            )}
            {isLaptop && (
                <div className={classNames(styles['panel__column'], styles['panel__column--blue'])}>
                    {Right}
                </div>
            )}
            {!hideCircle && <YotiCircle data-qa="circle" />}
        </div>
    );
};

export default FaceMatchingTwoPanels;
