import { Endpoint } from '../../models/prediction';
import { ConfigProps } from '../config/ConfigPanel';
import Config from '../../utils/config';

export const FACE_MATCHING_CONFIG: ConfigProps = {
    selectedEndpoint: Endpoint.FaceMatching,
    selectedSecureMode: Config.defaultConfig.mode,
    endpoints: [{ key: Endpoint.FaceMatching }],
    selectedAgeDisplayMode: Config.defaultConfig.ageDisplayMode,
    selectedLanguage: Config.defaultConfig.language,
    consent: false,
};

export enum FaceMatchingPanelTabs {
    Guide = 'Guide',
    Capture = 'Capture',
    Settings = 'Settings',
}
