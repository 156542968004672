import Icon from '@yoti/react-components/icon';
import * as iconsList from '@yoti/react-components/icons-list';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { MediaQuery, useClickOutside, useMediaQuery } from '../../hooks';
import styles from './dragAndDrop.module.scss';

// Messages.
const LAPTOP_CONTAINER_MESSAGE = 'Drag and drop to add an image';

interface Props {
    primaryPanel: boolean;
    infoMsgs: string[];
    image?: string;

    onError: (error: Error) => void;
    onClick: () => void;
    onFilesLoad: (files?: FileList) => void;
}

const DragAndDrop: React.FC<Props> = ({
    onError,
    onClick,
    onFilesLoad,
    primaryPanel,
    image,
    infoMsgs,
}) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const isLaptop = useMediaQuery(MediaQuery.lg);

    const handleFileDrop = (e: React.DragEvent) => {
        e.preventDefault();
        if (e.dataTransfer) {
            const { files } = e.dataTransfer;
            if (files.length) {
                onFilesLoad(files);
            }
        } else {
            onError(new Error("Couldn't load the file."));
        }
    };

    const removeImage = (e: React.MouseEvent | React.KeyboardEvent) => {
        e.stopPropagation();
        onFilesLoad(undefined);
    };

    const openTooltip = (open: boolean, e: React.MouseEvent | React.KeyboardEvent) => {
        e.stopPropagation();
        setShowTooltip(open);
    };

    const tooltipRef = useRef(null);
    useClickOutside(tooltipRef, (e) => {
        openTooltip(false, e);
    });

    return (
        <div
            className={classNames(
                styles['drag-and-drop'],
                primaryPanel
                    ? styles['drag-and-drop__container__border']
                    : styles['drag-and-drop__container--secondary'],
            )}
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={(e) => e.preventDefault()}
            onDragLeave={(e) => e.preventDefault()}
            onDrop={handleFileDrop}
            onKeyDown={onClick}
            onClick={onClick}
            role="button"
            tabIndex={0}
            data-qa="container"
        >
            {image ? (
                <>
                    <img
                        className={styles['drag-and-drop__image-loaded']}
                        src={image}
                        alt={'upload'}
                        data-qa="image"
                    ></img>
                    <div
                        className={styles['drag-and-drop__file-remove']}
                        onKeyDown={(e) => removeImage(e)}
                        onClick={(e) => removeImage(e)}
                        role="button"
                        tabIndex={0}
                        data-qa="remove-icon"
                    >
                        <Icon component={iconsList['clear']} />
                    </div>
                </>
            ) : (
                <div className={styles['drag-and-drop__info']}>
                    <div
                        role="button"
                        tabIndex={0}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                        onClick={(e) => openTooltip(true, e)}
                        onKeyDown={(e) => openTooltip(true, e)}
                        className={classNames(
                            styles['drag-and-drop__help-icon-container'],
                            primaryPanel ? styles['drag-and-drop__container--primary'] : undefined,
                        )}
                        data-qa="help-icon"
                    >
                        <Icon component={iconsList['help']} />

                        {showTooltip && (
                            <div
                                data-qa="tooltip"
                                ref={tooltipRef}
                                className={styles['drag-and-drop__tooltip']}
                                role="tooltip"
                            >
                                Keep in mind a few things:
                                <ul className={styles['drag-and-drop__tooltip__list']}>
                                    {infoMsgs.map((requirement, index) => (
                                        <li key={index}>{requirement}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>

                    <Icon
                        className={classNames(
                            styles['drag-and-drop__info__camera-icon'],
                            primaryPanel ? styles['drag-and-drop__container--primary'] : undefined,
                        )}
                        data-qa="camera-icon"
                        component={iconsList['camera']}
                    />

                    <span
                        className={classNames(
                            styles['drag-and-drop__info__drop-message'],
                            primaryPanel ? styles['drag-and-drop__container--primary'] : undefined,
                        )}
                        data-qa="drop-message"
                    >
                        {isLaptop ? LAPTOP_CONTAINER_MESSAGE : ''}
                    </span>
                </div>
            )}
        </div>
    );
};

export default DragAndDrop;
