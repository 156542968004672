import React from 'react';
import { FirstLanding } from '../../../hooks/firstLanding';
import { FACE_MATCHING_CONFIG_KEY } from '../../../utils/constants/storage';
import { SubtextColor } from '../../commons';
import GuidePanel from '../../guide';

interface Props {
    className?: string;
    firstLandingConfig: FirstLanding;
    set: (previous: any) => void;
}

const Guide: React.FC<Props> = ({ className, firstLandingConfig, set }) => {
    return (
        <div className={className}>
            <GuidePanel
                section={FACE_MATCHING_CONFIG_KEY}
                firstLandingConfig={firstLandingConfig}
                set={set}
            >
                <>
                    Select the desired endpoint at the settings tab.
                    <SubtextColor>(Default is Standalone Face Matching)</SubtextColor>
                </>
                <>Go to the Capture tab and follow the instructions to provide both pictures.</>
            </GuidePanel>
        </div>
    );
};

export default Guide;
