import {
    SCAN_FIRST_LANDING_CONFIG_KEY,
    FACE_MATCHING_FIRST_LANDING_CONFIG_KEY,
} from '../utils/constants/storage';
import { useGetLocalStorage } from './getLocalStorage';
import { useLocalStorage } from './storage';

function isBoolean(config: any): config is boolean {
    return !(config === undefined || null);
}

export interface FirstLanding {
    /** First landing configuration for scan section. */
    scanFirstLanding: boolean;
    /** First landing configuration setter for scan section. */
    setScanFirstLanding: (scanFirstLanding: boolean) => void;
    /** First landing configuration for face matching section. */
    faceMatchingFirstLanding: boolean;
    /** First landing configuration setter for face matching section. */
    setFaceMatchingFirstLanding: (faceMatchingFirstLanding: boolean) => void;
}

export const FIRST_LANDING_DEFAULT_CONFIG: FirstLanding = {
    scanFirstLanding: true,
    setScanFirstLanding: () => {},
    faceMatchingFirstLanding: true,
    setFaceMatchingFirstLanding: () => {},
};

export function useFirstLanding(): FirstLanding {
    const scanFirstLandingFromLocalStorage = useGetLocalStorage(
        SCAN_FIRST_LANDING_CONFIG_KEY,
        isBoolean,
    );
    const [scanFirstLanding, setScanFirstLanding] = useLocalStorage(
        SCAN_FIRST_LANDING_CONFIG_KEY,
        scanFirstLandingFromLocalStorage == undefined ? true : scanFirstLandingFromLocalStorage,
        isBoolean,
    );

    const faceMatchingFirstLandingFromLocalStorage = useGetLocalStorage(
        FACE_MATCHING_FIRST_LANDING_CONFIG_KEY,
        isBoolean,
    );
    const [faceMatchingFirstLanding, setFaceMatchingFirstLanding] = useLocalStorage(
        FACE_MATCHING_FIRST_LANDING_CONFIG_KEY,
        faceMatchingFirstLandingFromLocalStorage == undefined
            ? true
            : faceMatchingFirstLandingFromLocalStorage,
        isBoolean,
    );
    const firstLandingConfig: FirstLanding = {
        scanFirstLanding,
        setScanFirstLanding,
        faceMatchingFirstLanding,
        setFaceMatchingFirstLanding,
    };
    return firstLandingConfig;
}
