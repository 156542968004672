import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app';
import reportWebVitals from './reportWebVitals';
import isLocalStorageAvailable from './components/app/localStorageAvailable';
import CookiesAlert from './components/alert/cookiesAlert';

// Check if the user rejects the cookies which are related to the local storage we use.
const cookiesEnabled = isLocalStorageAvailable();

ReactDOM.render(
    <React.StrictMode>{cookiesEnabled ? <App /> : <CookiesAlert />}</React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
