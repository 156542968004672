import React from 'react';
import Loading from '@yoti/react-components/loading';
import style from './circularLoading.module.scss';

const DEFAULT_SIZE = '125px';
const DEFAULT_MESSAGE = 'Loading...';

export interface Props {
    size?: string;
    message?: string;
}

const CircularLoading: React.FC<Props> = (props) => {
    const { size, message } = props;
    return (
        <div className={style.loading}>
            <Loading size={size ? size : DEFAULT_SIZE} />
            <span data-qa="loading-message">{message ? message : DEFAULT_MESSAGE}</span>
        </div>
    );
};

export default CircularLoading;
