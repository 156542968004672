import React from 'react';
import styles from './faceCaptureError.module.scss';
import { ReactComponent as WarningIcon } from './alert.svg';
import Button, { BUTTON_VARIATIONS, BUTTON_SIZES } from '@yoti/react-components/button';

export interface Props {
    errorMessage: string;
    onReset: () => void;
}

export const FaceCaptureError: React.FC<Props> = ({ errorMessage, onReset }) => (
    <div className={styles.root}>
        <div className={styles.block}>
            <WarningIcon className={styles.icon} />
            <div className={styles.error}>
                <p className={styles.error_header}>An error has occured.</p>
                <p className={styles.error_message}>{errorMessage}</p>
            </div>
        </div>
        <div className={styles.button}>
            <Button
                onClick={onReset}
                variation={BUTTON_VARIATIONS.DANGER}
                size={BUTTON_SIZES.LARGE}
                fullWidth
            >
                Retry
            </Button>
        </div>
    </div>
);
