import SelectField from '@yoti/react-components/select-field';
import '@yoti/react-components/select-field/select-field.css';
import React, { ChangeEventHandler } from 'react';
import { ConfigSection } from '../ConfigSection';

export interface ConfigThresholdProps {
    value?: number;
    onChange: (value?: number) => void;
    disabled: boolean;
}

const thresholdOptions = Object.values(Array.from({ length: 53 }, (_, i) => i + 12 + 1)).map(
    (threshold) => ({
        value: threshold,
        label: `${threshold}`,
    }),
);

const ThresholdFieldSection: React.FC<ConfigThresholdProps> = ({ value, onChange, disabled }) => {
    const handleChange: ChangeEventHandler<HTMLSelectElement> = ({ target: { value } }) => {
        onChange(parseInt(value, 10));
    };
    return (
        <ConfigSection title="Threshold">
            <SelectField
                name="threshold"
                options={thresholdOptions}
                label="Threshold"
                selectedValue={value}
                onChange={handleChange}
                disabled={disabled}
            />
        </ConfigSection>
    );
};

export default ThresholdFieldSection;
