import { ALERT_TYPES } from '@yoti/react-components/alert';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import useNotify from '../useNotify';
import { RequestConfig as BaseRequestConfig, useApi as baseUseApi } from './base/useApi';

interface RequestConfig extends BaseRequestConfig {
    hideErrorNotification?: (e: AxiosError) => boolean;
}

/**
 * Custom hook that will performs an API request and show a notification in case
 * it fails.
 * @param url The base url the the hook will requset.
 * @param options Request options.
 */
export default function useApi<T>(url: string, options?: RequestConfig) {
    const api = baseUseApi<T>(url, options);
    const { error } = api;
    const notify = useNotify();
    const hideErrorNotification = options?.hideErrorNotification;

    useEffect(() => {
        if (error && (!hideErrorNotification || !hideErrorNotification(error))) {
            const msg = `Request failed (${error.response?.status}): ${latestErrorMsg(
                error.message,
            )}`;
            notify({
                message: msg,
                type: ALERT_TYPES.DANGER,
                icon: true,
            });
        }
    }, [error, hideErrorNotification, notify]);

    return { ...api, error };
}

/**
 * Returns the error reason from the error message. It is the latest segment in
 * 'str' as the back-end can return an error with the following format
 * '<text>::<text>::...::<text>' (the token that joins all segments is '::').
 * @param str
 */
function latestErrorMsg(str: string): string {
    const messages = str.split('::');
    if (messages.length === 0) {
        return '';
    }
    return messages[messages.length - 1].trim();
}
