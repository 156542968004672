import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Alert, { AlertProps } from './Alert';
import styles from './alertProvider.module.scss';
import AlertContext from './context';
import { ALERT_TYPES } from '@yoti/react-components/alert';

interface AlertComponentProps extends AlertProps {
    id: string;
}

const DEFAULT_DANGER_HEADER = 'Oops! There was an error';

const setHeader = (type: string, header?: string): string | undefined => {
    switch (type) {
        case ALERT_TYPES.DANGER:
            return header ? header : DEFAULT_DANGER_HEADER;
        default:
            return header;
    }
};

const AlertProvider: React.FC = ({ children }) => {
    const [messages, setMessages] = useState<AlertComponentProps[]>([]);

    const notify = useCallback((props: AlertProps) => {
        const key = uuidv4(); // Generate a random key to identify the message.

        const alertProps: AlertComponentProps = {
            id: key,
            header: setHeader(props.type, props.header),
            ...props,
        };
        setMessages((previous) => previous.concat(alertProps));
    }, []);

    const onAlertTimeout = useCallback((key: string) => {
        setMessages((previous) => previous.filter((m) => m.id !== key));
    }, []);

    // Reverse the messages list to show new messages at top.
    const reverseMessages = messages.concat([]).reverse();

    return (
        <AlertContext.Provider value={{ notify }}>
            {reverseMessages.length > 0 && (
                <div className={styles['alert-provider']}>
                    {reverseMessages.map((m) => (
                        <Alert key={m.id} onTimeout={onAlertTimeout} {...m} />
                    ))}
                </div>
            )}
            {children}
        </AlertContext.Provider>
    );
};

export default AlertProvider;
