import './radio-button.css';
import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';

const tabIndexTabbable = '0';
const tabIndexNotTabbable = '-1';
const SPACE_KEY = ' ';

const RadioButton = ({ selectedValue, label, value, disabled, 'data-qa': dataQa, onChange }) => {
  const checked = selectedValue === value;

  const tabIndex = !disabled ? tabIndexTabbable : tabIndexNotTabbable;

  const rootClasses = classnames('yoti-radio-button', {
    'yoti-radio-button--disabled': disabled,
  });

  const inputClasses = classnames('yoti-radio-button__input', {
    'yoti-radio-button__input--checked': checked,
  });

  const handleSelection = () => {
    if (!disabled) {
      onChange(value);
    }
  };

  const handleClick = () => {
    handleSelection();
  };

  const handleKeyPress = (event) => {
    event.preventDefault();

    if (event.key !== SPACE_KEY) return;

    handleSelection();
  };

  return (
    <div
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      tabIndex={tabIndex}
      className={rootClasses}
      role="radio"
      aria-checked={checked}
      data-qa={dataQa}
    >
      <div className={inputClasses} />
      {label && <div className="yoti-radio-buttons__label">{label}</div>}
    </div>
  );
};

RadioButton.defaultProps = {
  selectedValue: undefined,
  disabled: false,
  'data-qa': 'radio-button',
  onChange: () => {},
};

RadioButton.propTypes = {
  selectedValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  'data-qa': PropTypes.string,
  onChange: PropTypes.func,
};

export default RadioButton;
