import { Alert as YotiAlert, ALERT_SIZES } from '@yoti/react-components/alert';
import React, { useEffect } from 'react';
import styles from './alert.module.scss';

export interface AlertProps {
    message: string;
    type: string;
    icon: boolean;
    delay?: number;
    header?: string;
}

export interface Props extends AlertProps {
    id: string;
    onTimeout: (key: string) => void;
}

const DEFAULT_DELAY = 5000;

const Alert: React.FC<Props> = ({ id, message, type, icon, delay, header, onTimeout }) => {
    useEffect(() => {
        const timeoutId = setTimeout(() => onTimeout(id), delay ? delay : DEFAULT_DELAY);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [delay, onTimeout, id]);

    return (
        <div className={styles['alert']}>
            <YotiAlert icon={icon} type={type} size={ALERT_SIZES.SMALL}>
                {header && (
                    <>
                        <span data-qa="header-text" className={styles['alert__header']}>
                            {header}
                        </span>
                        <br />
                    </>
                )}

                <span data-qa="msg-text">{message}</span>
            </YotiAlert>
        </div>
    );
};

export default Alert;
