import React, { useEffect } from 'react';
import { useGlobalContext } from '../../globalContext';
import { useConfigStorage } from '../../hooks';
import {
    AGE_ANTISPOOFING_CONFIG_KEY,
    FACE_MATCHING_CONFIG_KEY,
} from '../../utils/constants/storage';
import { fillUndefinedValues } from '../config/ConfigPanel';
import { FACE_MATCHING_CONFIG } from '../face-matching-panel/models';
import { DEFAULT_CONFIG as SCAN_DEFAULT_CONFIG } from '../scan-panel/models';

const LocalConfig: React.FC = ({ children }) => {
    const globalContext = useGlobalContext();
    const { account, set } = globalContext;

    const [scanConfiguration, setScanConfiguration] = useConfigStorage(
        AGE_ANTISPOOFING_CONFIG_KEY,
        SCAN_DEFAULT_CONFIG,
        account?.role,
    );

    const [faceMatchingConfiguration, setFaceMatchingConfiguration] = useConfigStorage(
        FACE_MATCHING_CONFIG_KEY,
        FACE_MATCHING_CONFIG,
        account?.role,
    );

    useEffect(() => {
        set((prev) => {
            fillUndefinedValues(scanConfiguration);
            fillUndefinedValues(faceMatchingConfiguration);
            return {
                ...prev,
                scanConfiguration,
                setScanConfiguration,
                faceMatchingConfiguration,
                setFaceMatchingConfiguration,
            };
        });
    }, [
        faceMatchingConfiguration,
        scanConfiguration,
        set,
        setFaceMatchingConfiguration,
        setScanConfiguration,
    ]);

    return <>{children}</>;
};

export default LocalConfig;
