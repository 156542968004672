import { useEffect, useState } from 'react';

export type OrientationType = 'portrait' | 'landscape';

const SUPPORTS_ANGLE = window?.screen?.orientation?.angle !== undefined;

const getOrientation = (orientation: number): OrientationType => {
    switch (orientation) {
        case 90:
        case -90:
        case 270:
            return 'landscape';
        default:
            return 'portrait';
    }
};

const getInitialOrientation = (): OrientationType => {
    const orientationValue: number = SUPPORTS_ANGLE
        ? window.screen.orientation.angle // Try using the not deprecated option
        : Number(window.orientation); // If it is not possible, use the deprecated option.

    return getOrientation(orientationValue);
};

/** useScreenOrientation returns the updated screen orientation. */
export const useScreenOrientation = () => {
    const [orientation, setOrientation] = useState<OrientationType>(getInitialOrientation());

    useEffect(() => {
        // The type of `event` is `any` due to its actual value is not exported on TypeScript.
        const notDeprecatedOrientationChange = (event: any) =>
            setOrientation(getOrientation(event.currentTarget.angle));
        const deprecatedOrientationChange = () =>
            setOrientation(getOrientation(Number(window.orientation)));

        if (SUPPORTS_ANGLE) {
            // Try using the not deprecated option
            window.screen.orientation.onchange = notDeprecatedOrientationChange;
            return () => {
                window.screen.orientation.onchange = null;
            };
        } else {
            // If it is not possible, use the deprecated option.
            window.addEventListener('orientationchange', deprecatedOrientationChange);
            return () =>
                window.removeEventListener('orientationchange', deprecatedOrientationChange);
        }
    }, []);

    return orientation;
};
