import classNames from 'classnames';
import React from 'react';
import { MediaQuery, useMediaQuery } from '../../hooks';
import styles from './twoSidesPanel.module.scss';
import YotiCircle from './YotiCircle';

interface Props {
    children: {
        Header?: React.ReactNode;
        Left: React.ReactNode;
        Right: React.ReactNode;
        Footer?: React.ReactNode;
    };
    hideCircle?: boolean;
    mobileContentClassName?: string;
}

const TwoSidesPanel: React.FC<Props> = ({ hideCircle, children, mobileContentClassName }) => {
    const { Header, Left, Right, Footer } = children;
    const isLaptop = useMediaQuery(MediaQuery.lg);

    return (
        <div className={styles['panel']}>
            {isLaptop ? (
                <>
                    <div className={styles['panel__primary']} data-qa="primary-panel">
                        {Left}
                    </div>
                    <div className={styles['panel__secondary']}>
                        {Header && (
                            <div className={styles['panel__header']} data-qa="header">
                                <span className={styles['panel__right-header']}>{Header}</span>
                            </div>
                        )}
                        <div
                            className={styles['panel__secondary__content']}
                            data-qa="secondary-panel"
                        >
                            {Right}
                        </div>

                        {Footer && (
                            <div className={styles['panel__right-footer']} data-qa="footer">
                                <div className={styles['panel__right-footer__container']}>
                                    {Footer}
                                </div>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <div className={styles['panel__header']} data-qa="header">
                        <span className={styles['panel__right-header']}>{Header}</span>
                    </div>
                    <div
                        className={classNames(
                            styles['panel__mobile-body-wrapper'],
                            mobileContentClassName,
                        )}
                        data-qa="mobile-wrapper"
                    >
                        {Left}
                        {Right}
                    </div>
                    {Footer && (
                        <div className={styles['panel__right-footer']} data-qa="footer">
                            <div className={styles['panel__right-footer__container']}>{Footer}</div>
                        </div>
                    )}
                </>
            )}
            {!hideCircle && <YotiCircle data-qa="circle" />}
        </div>
    );
};

export default TwoSidesPanel;
