import React from 'react';
import classes from './button.module.scss';

interface Props {
    className?: string;
    onClick: () => void;
}

const Button: React.FC<Props> = ({ className, onClick }) => {
    return <button type="button" className={`${classes.button} ${className}`} onClick={onClick} />;
};

export default Button;
