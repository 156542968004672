import Icon from '@yoti/react-components/icon';
import * as iconsList from '@yoti/react-components/icons-list';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AccountService from '../../api/account/account';
import { isLoggedIn, useGlobalContext } from '../../globalContext';
import Config from '../../utils/config';
import { LOGIN_PATH, ROOT_PATH } from '../../utils/constants/Routes';
import { Dialog } from '../commons';
import { ConfigProps } from '../config/ConfigPanel';
import styles from './header.module.scss';

const accountService = new AccountService(Config.apiUrl);

interface Props {
    hasLinkToMainPage: boolean;
}

const Header: React.FC<Props> = ({ hasLinkToMainPage }) => {
    const [openLogoutDialog, setOpenLogoutDialog] = useState<boolean>(false);
    const globalContext = useGlobalContext();
    const { account, set } = globalContext;

    const history = useHistory();
    const logged = isLoggedIn(account);

    const handleLogout = () => {
        accountService.logout();

        // Set consent to be false if the user logout.
        const newScanConfig = Object.assign(new ConfigProps(), globalContext.scanConfiguration);
        newScanConfig.consent = false;
        globalContext.setScanConfiguration(newScanConfig);

        set({
            ...globalContext,
            account: undefined,
            accountChecked: false,
            scanConfiguration: newScanConfig,
        });
        history.push(LOGIN_PATH);
    };

    return (
        <>
            {logged && (
                <div className={styles['header']}>
                    {hasLinkToMainPage && (
                        <Icon
                            component={iconsList[`house`]}
                            className={`${styles['header__icon']} ${styles['header__icon--main-page']}`}
                            onClick={() => {
                                set({
                                    ...globalContext,
                                    scanSelectedTab: undefined,
                                    faceMatchingSelectedTab: undefined,
                                });
                                history.push(ROOT_PATH);
                            }}
                            data-qa="main-page-icon"
                        />
                    )}
                    <Icon
                        component={iconsList[`logOut`]}
                        className={`${styles['header__icon']} ${styles['header__icon--log-out']}`}
                        onClick={() => setOpenLogoutDialog(true)}
                        data-qa="log-out-icon"
                    />
                    {openLogoutDialog && (
                        <Dialog
                            title={'Log out'}
                            action={'log out'}
                            onConfirm={handleLogout}
                            onClose={() => setOpenLogoutDialog(false)}
                            message={'log out'}
                        ></Dialog>
                    )}
                </div>
            )}
        </>
    );
};

export default Header;
