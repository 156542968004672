import './alert.scss';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../icon';
import { information } from '../icons-list';

import * as SIZES from './alertSizes';
import * as TYPES from './alertTypes';

export function Alert({ children, heading, icon, type, size, fullWidth, id, 'data-qa': dataQa }) {
  const isValidType = Object.values(TYPES).includes(type);

  const alertStyle = classnames(
    'yoti-alert',
    {
      'yoti-alert--small': size === SIZES.SMALL,
      'yoti-alert--large': size === SIZES.LARGE,
      'yoti-alert--has-icon': icon,
      'yoti-alert--full-width': fullWidth,
    },
    isValidType && `yoti-alert--${type}`
  );

  const hasDefaultIcon = typeof icon === 'boolean';

  return (
    <section className={alertStyle} data-qa={dataQa} id={id}>
      {icon && (
        <div className="yoti-alert__icon" data-qa="yoti-alert-icon">
          <Icon component={hasDefaultIcon ? information : icon} />
        </div>
      )}
      <div className="yoti-alert__content">
        {heading && (
          <h4 className="yoti-alert__heading" data-qa="yoti-alert-heading">
            {heading}
          </h4>
        )}
        <p className="yoti-alert__description" data-qa="yoti-alert-description">
          {children}
        </p>
      </div>
    </section>
  );
}

Alert.defaultProps = {
  children: null,
  type: TYPES.INFO,
  size: SIZES.SMALL,
  heading: undefined,
  id: undefined,
  icon: false,
  fullWidth: false,
  'data-qa': 'yoti-alert',
};

Alert.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(Object.values(TYPES)),
  size: PropTypes.oneOf(Object.values(SIZES)),
  id: PropTypes.string,
  heading: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  fullWidth: PropTypes.bool,
  'data-qa': PropTypes.string,
};
