import React from 'react';
import styles from './notFound.module.scss';
import Icon from '@yoti/react-components/icon';
import * as iconsList from '@yoti/react-components/icons-list';

const NotFound: React.FC = () => (
    <div className={styles['not-found']}>
        <Icon component={iconsList['prohibited']} className={styles['not-found__icon']} />
        <div className={styles['not-found__text']}>Page not found</div>
    </div>
);

export default NotFound;
