import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { createUniqueId } from '../services/utils';
import { ReactComponent as Tick } from '../assets/checkbox.svg';
import './checkbox.scss';

const prefixIdentifier = 'yoti-input-checkbox';

function Checkbox({ checked, label, disabled, id, onChange, 'data-qa': dataQa }) {
  const [defaultId] = useState(`${prefixIdentifier}-${createUniqueId()}`);
  const idValue = id || defaultId;

  const rootClasses = classnames('yoti-checkbox', {
    'yoti-checkbox--disabled': disabled,
  });

  const handleOnChange = ({ target: { checked: value } }) => {
    if (disabled) {
      return;
    }

    onChange(value);
  };

  return (
    <span className={rootClasses}>
      <span className="yoti-checkbox-input">
        <input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          id={idValue}
          onChange={handleOnChange}
          className="yoti-checkbox__input-element"
          data-qa={dataQa}
        />
        <Tick className="yoti-checkbox-tick" />
      </span>

      {label && (
        <label htmlFor={idValue} className="yoti-checkbox-label">
          {label}
        </label>
      )}
    </span>
  );
}

Checkbox.defaultProps = {
  checked: undefined,
  disabled: false,
  label: '',
  id: undefined,
  onChange: () => {},
  'data-qa': prefixIdentifier,
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.string,
  onChange: PropTypes.func,
  'data-qa': PropTypes.string,
};

export default Checkbox;
