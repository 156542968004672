import React, { useEffect, useState } from 'react';
import CircularLoading, { Props as CircularLoadingProps } from './../loading';

export const DEFAULT_LOADING_MESSAGE = 'Sending image to server...';
export const TIMEOUT_MESSAGE = 'Server is taking longer than usual, please wait...';
export const TIMEOUT_DELAY = 5000;

interface Props extends CircularLoadingProps {
    timeoutMessage: string;
    timeout: number;
}

const TimeoutCircularLoading: React.FC<Props> = (props) => {
    const { timeoutMessage, timeout, message, size } = props;

    const [loadingMessage, setLoadingMessage] = useState<string | undefined>(message);

    useEffect(() => {
        const timeoutRef = setTimeout(() => {
            setLoadingMessage(timeoutMessage);
        }, timeout);

        return () => clearTimeout(timeoutRef);
    });

    return <CircularLoading message={loadingMessage} size={size} />;
};

export default TimeoutCircularLoading;
